import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createOfferPrice } from 'client/pages/offer/entities/OfferPrice'
import { migrateMargin, migrateMobileMargin } from '../utils/migrateUtils'

export function migrateOfferPrice(entity: OldEntityInterface): EntityInterface {
  const offerPrice = createOfferPrice(entity.parentId, entity.masterBlockId)
  //id and masterBlock
  offerPrice.id = entity.id
  if (entity.isMasterBlockRoot) {
    offerPrice.isMasterBlockRoot = true
  }

  if (entity.options.customPriceDescription) {
    offerPrice.customDescription = entity.options.customPriceDescription
  }

  //margins
  migrateMargin(offerPrice, entity)
  migrateMobileMargin(offerPrice, entity)
  //appearance
  if (entity.options.appearance) {
    offerPrice.appearance.desktop = entity.options.appearance.desktop
    offerPrice.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  offerPrice.htmlAttrId = entity.options.attrId

  return offerPrice
}
