import {
  migrateBackgroundColor,
  migrateBoxShadow,
  migrateFullBorderType,
  migrateJustifyContentToTextAlign,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createContentBox } from 'client/components/core/Sidebar/components/Settings/entities/ContentBoxSettings/utils/create-content-box'

export function migrateContentBox(entity: OldEntityInterface) {
  const contentBox = createContentBox(entity.parentId, entity.masterBlockId)

  if (entity.childIds && entity.childIds.length !== 0) {
    contentBox.childIds = entity.childIds
  }

  contentBox.id = entity.id
  contentBox.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    contentBox.appearance.desktop = entity.options.appearance.desktop
    contentBox.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(contentBox, entity)
  migrateMobileMargin(contentBox, entity)

  if (entity.styles.padding) {
    contentBox.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    contentBox.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateFullBorderType(contentBox, entity)
  migrateBoxShadow(contentBox, entity)

  //text
  if (entity.options.headerStyles.fontFamily) {
    contentBox.header.fontFamily = entity.options.headerStyles.fontFamily
  }
  if (entity.options.headerStyles.fontWeight) {
    contentBox.header.fontWeight = entity.options.headerStyles.fontWeight
  }
  if (entity.options.headerStyles.fontStyle) {
    contentBox.header.fontStyle = entity.options.headerStyles
  }
  //mobileText
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.fontFamily
  ) {
    contentBox.header.mobileFontFamily =
      entity.mobileOptions.headerStyles.fontFamily
  }
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.fontWeight
  ) {
    contentBox.header.mobileFontWeight =
      entity.mobileOptions.headerStyles.fontWeight
  }
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.fontStyle
  ) {
    contentBox.header.mobileFontStyle =
      entity.mobileOptions.headerStyles.fontStyle
  }
  //custom
  if (entity.options.fontFileId) {
    contentBox.header.fontFileId = entity.options.fontFileId
  }
  //mobileCustom
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.fontFileId
  ) {
    contentBox.header.mobileFontFileId = entity.mobileOptions.fontFileId
  }

  // "0" || "1"
  contentBox.header.visibility = Boolean(
    Number(entity.options.headerVisibility),
  )

  contentBox.header.title = entity.options.headerTitle

  if (entity.options.delay) {
    contentBox.delay = entity.options.delay
  }

  //fontSize
  if (entity.styles.fontSize && !isNaN(parseInt(entity.styles.fontSize))) {
    contentBox.header.fontSize = parseInt(entity.styles.fontSize)
  }
  if (
    entity.mobileStyles.fontSize &&
    !isNaN(parseInt(entity.mobileStyles.fontSize))
  ) {
    contentBox.header.mobileFontSize = parseInt(entity.mobileStyles.fontSize)
  }

  migrateBackgroundColor(contentBox, entity)

  if (entity.options.headerStyles.color) {
    contentBox.header.color = entity.options.headerStyles.color
  }
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.color
  ) {
    contentBox.header.mobileColor = entity.mobileOptions.headerStyles.color
  }

  if (entity.options.headerStyles.backgroundColor) {
    contentBox.header.backgroundColor =
      entity.options.headerStyles.backgroundColor
  }
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.backgroundColor
  ) {
    contentBox.header.mobileBackgroundColor =
      entity.mobileOptions.headerStyles.backgroundColor
  }

  if (entity.options.headerStyles.justifyContent) {
    contentBox.header.textAlign = migrateJustifyContentToTextAlign(
      entity.options.headerStyles.justifyContent,
    )
  }
  if (
    entity.mobileOptions &&
    !Array.isArray(entity.mobileOptions) &&
    entity.mobileOptions.headerStyles?.justifyContent
  ) {
    contentBox.header.mobileTextAlign = migrateJustifyContentToTextAlign(
      entity.mobileOptions.headerStyles.justifyContent,
    )
  }

  return contentBox
}
