import EntityTypeEnum from '../../../../common/enums/entityTypeEnum'
import CarouselInterface, {
  CarouselItemInterface,
} from '../../../../common/types/entities/CarouselInterface'

export function migrateCarousel(entity: CarouselInterface) {
  return {
    ...entity,
    type: EntityTypeEnum.Carousel,
  }
}

export function migrateCarouselItem(entity: CarouselItemInterface) {
  return {
    ...entity,
    type: EntityTypeEnum.CarouselItem,
  }
}
