import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { OrderBumpInterface } from 'common/types/entities/order-bump-interface'
import { createOrderBump } from 'client/pages/offer/entities/order-bump'
import { ColorInterface } from '../menu/migrateMenuUtils'
import {
  FontInterface,
  FontSizeInterface,
  migrateBoxShadow,
  migrateFullBorderType,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

function migrateFontSettings(
  newEntity: FontInterface,
  originalEntity: OldEntityInterface,
) {
  //text
  if (originalEntity.options.headerStyles.fontFamily) {
    newEntity.fontFamily = originalEntity.options.headerStyles.fontFamily
  }
  if (originalEntity.options.headerStyles.fontWeight) {
    newEntity.fontWeight = originalEntity.options.headerStyles.fontWeight
  }
  if (originalEntity.options.headerStyles.fontStyle) {
    newEntity.fontStyle = originalEntity.options.headerStyles.fontStyle
  }
  if (originalEntity.options.fontFileId) {
    newEntity.fontFileId = originalEntity.options.fontFileId
  }

  if (
    !originalEntity.mobileOptions ||
    !originalEntity.mobileOptions.headerStyles
  ) {
    return
  }

  if (
    !originalEntity.mobileOptions ||
    !originalEntity.mobileOptions.headerStyles
  ) {
    return
  }

  //mobileText
  if (originalEntity.mobileOptions.headerStyles.fontFamily) {
    newEntity.mobileFontFamily =
      originalEntity.mobileOptions.headerStyles.fontFamily
  }
  if (originalEntity.mobileOptions.headerStyles.fontWeight) {
    newEntity.mobileFontWeight =
      originalEntity.mobileOptions.headerStyles.fontWeight
  }
  if (originalEntity.mobileOptions.headerStyles.fontStyle) {
    newEntity.mobileFontStyle =
      originalEntity.mobileOptions.headerStyles.fontStyle
  }
  if (originalEntity.mobileOptions.fontFileId) {
    newEntity.mobileFontFileId = originalEntity.mobileOptions.fontFileId
  }
}

function migrateFontSize(
  newEntity: FontSizeInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.options.headerStyles.fontSize &&
    !isNaN(parseInt(originalEntity.options.headerStyles.fontSize))
  ) {
    newEntity.fontSize = parseInt(originalEntity.options.headerStyles.fontSize)
  }

  if (
    !originalEntity.mobileOptions ||
    !originalEntity.mobileOptions.headerStyles
  ) {
    return
  }

  if (
    originalEntity.mobileOptions.headerStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.headerStyles.fontSize))
  ) {
    newEntity.mobileFontSize = parseInt(
      originalEntity.mobileOptions.headerStyles.fontSize,
    )
  }
}

function migrateHeaderBackgroundColor(
  newEntity: OrderBumpInterface,
  originalEntity: OldEntityInterface,
) {
  //backgroundColor
  if (originalEntity.options.headerStyles.backgroundColor) {
    newEntity.headerBackgroundColor =
      originalEntity.options.headerStyles.backgroundColor
  }
  //mobileBackgroundColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.headerStyles &&
    originalEntity.mobileOptions.headerStyles.backgroundColor
  ) {
    newEntity.mobileHeaderBackgroundColor =
      originalEntity.mobileOptions.headerStyles.backgroundColor
  }
}

function migrateColor(
  newEntity: ColorInterface,
  originalEntity: OldEntityInterface,
) {
  if (originalEntity.options.headerStyles.color) {
    newEntity.color = originalEntity.options.headerStyles.color
  }

  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.headerStyles &&
    originalEntity.mobileOptions.headerStyles.color
  ) {
    newEntity.mobileColor = originalEntity.mobileStyles.color
  }
}

export function migrateOrderBump(entity: OldEntityInterface): EntityInterface {
  const orderBump = createOrderBump(entity.parentId, '', entity.masterBlockId)
  //id and masterBlock
  orderBump.id = entity.id
  if (entity.isMasterBlockRoot) {
    orderBump.isMasterBlockRoot = true
  }

  if (entity.options.title) {
    orderBump.title = entity.options.title
  }
  if (entity.options.headerStyles.justifyContent) {
    orderBump.justifyContent = entity.options.headerStyles.justifyContent
  }
  migrateFontSize(orderBump, entity)
  migrateFontSettings(orderBump, entity)
  migrateHeaderBackgroundColor(orderBump, entity)
  migrateColor(orderBump, entity)

  if (entity.options.showArrow !== '1') {
    orderBump.isArrowHidden = true
  }

  //margins
  migrateMargin(orderBump, entity)
  migrateMobileMargin(orderBump, entity)
  //paddings
  if (entity.styles.padding) {
    orderBump.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    orderBump.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }
  //appearance
  if (entity.options.appearance) {
    orderBump.appearance.desktop = entity.options.appearance.desktop
    orderBump.appearance.mobile = entity.options.appearance.mobile
  }
  migrateBoxShadow(orderBump, entity)
  migrateFullBorderType(orderBump, entity)
  //attrId
  orderBump.htmlAttrId = entity.options.attrId
  if (entity.childIds) {
    orderBump.childIds = entity.childIds
  }

  return orderBump
}
