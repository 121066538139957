import request from 'client/utils/request'

export const fetchPageData = (pageId, isTemplate) =>
  request.get(
    isTemplate ? `/page-template/${pageId}/edit` : `/page/${pageId}/edit`,
    {
      withCredentials: true,
    },
  )

export const previewPage = ({ content, locale }, isTemplate) =>
  request.post(
    isTemplate
      ? `/page-template/${content.id}/preview`
      : `/page/${content.id}/preview`,
    {
      editor_api_page_content: {
        content: JSON.stringify({
          entities: content.entities,
          seo: content.seo,
          tracking: content.tracking,
          globalSettings: content.globalSettings,
        }),
        locale,
      },
    },
  )

export const savePage = (page, isTemplate) => {
  return request.post(
    isTemplate
      ? `/page-template/${page.content.id}/save`
      : `/page/${page.content.id}/save`,
    {
      editor_api_page_content: {
        content: JSON.stringify({
          entities: page.content.entities,
          lastPopupNumber: page.content.lastPopupNumber,
          id: page.content.id,
          seo: page.content.seo,
          tracking: page.content.tracking,
          globalColor: page.content.globalColor,
          isTemplate: page.content.isTemplate,
          doubleOptIn: page.content.doubleOptIn,
          globalSettings: page.content.globalSettings,
        }),
        locale: page.locale,
      },
    },
  )
}

export const fetchPageAutomationRulesData = pageId => {
  return request.get(`/automation-rules-with-button-id-by-page-id/${pageId}`)
}

export const savePageAutomationRulesData = (pageId, payload) => {
  return request.post(`/update-automation-rules-with-page/${pageId}`, payload)
}
