import migrateAudioCommand from 'tools/commands/migrate/Audio/migrateAudioCommand'
import migrateBulletListCommand from 'tools/commands/migrate/BulletList/migrateBulletListCommand'
import migrateCheckboxCommand from 'tools/commands/migrate/Checkbox/migrateCheckboxCommand'
import migrateContentBoxCommand from 'tools/commands/migrate/ContentBox/migrateContentBoxCommand'
import migrateCountdownCommand from 'tools/commands/migrate/Countdown/migrateCountdownCommand'
import migrateHorizontalLineCommand from 'tools/commands/migrate/HorizontalLine/migrateHorizontalLineCommand'
import migrateInlineCommand from 'tools/commands/migrate/Inline/migrateInlineCommand'
import migrateLanguageSwitcherCommand from 'tools/commands/migrate/LanguageSwitcher/migrateLanguageSwitcherCommand'
import migrateRawHtmlCommand from 'tools/commands/migrate/RawHtml/migrateRawHtmlCommand'
import migrateContactUsRecaptchaElementCommand from 'tools/commands/migrate/Recaptcha/migrateContactUsRecaptchaElementCommand'
import migrateRowColumnCommand from 'tools/commands/migrate/RowColumnLayout/migrateRowColumnCommand'
import migrateSalesRedirectionButtonCommand from 'tools/commands/migrate/SalesRedirectionButton/migrateSalesRedirectionButtonCommand'
import migrateSectionCommand from 'tools/commands/migrate/Section/migrateSectionCommand'
import migrateTwitterTweetButtonCommand from 'tools/commands/migrate/TwitterTweetButton/migrateTwitterTweetButtonCommand'
import migrateUpsellButtonsCommand from 'tools/commands/migrate/UpsellButton/migrateUpsellButtonsCommand'
import migrateCarouselCommand from 'tools/commands/migrate/carousel/migrateCarouselCommand'
import migrateHeadlineToTsCommand from 'tools/commands/migrate/headline/migrateHeadlineToTsCommand'
import migrateImageCommand from 'tools/commands/migrate/image/migrateImageCommand'
import migrateMenuCommand from 'tools/commands/migrate/menu/migrateMenuCommand'
import migratePhysicalProductCommand from 'tools/commands/migrate/migratePhysicalProduct/migratePhysicalProductCommand'
import migrateOfferPriceCommand from 'tools/commands/migrate/offerPrice/migrateOfferPriceCommand'
import migratePaymentButtonCommand from 'tools/commands/migrate/paymentButton/migratePaymentButtonCommand'
import migratePaymentMethodCommand from 'tools/commands/migrate/paymentMethod/migratePaymentMethodCommand'
import migrateTextToTsCommand from 'tools/commands/migrate/text/migrateTextToTsCommand'
import migrateVideoCommand from 'tools/commands/migrate/video/migrateVideoCommand'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import migrateAgreementCommand from '../../tools/commands/migrate/Agreement/migrateAgreementCommand'
import migrateOrderBumpCommand from '../../tools/commands/migrate/orderBump/migrate-order-bump-command'

export const AUTOMIGRATE_DISABLED_PAGE_TYPES = [
  PageTypeEnum.BlogHome,
  PageTypeEnum.BlogPostBody,
  PageTypeEnum.BlogPostLayout,
  PageTypeEnum.BlogStatic,
]

export const AUTOMIGRATE_COMMAND_LIST = [
  // from ts
  migrateRawHtmlCommand,
  migrateCheckboxCommand,
  migrateBulletListCommand,
  migrateCountdownCommand,
  migratePaymentButtonCommand,
  migrateAgreementCommand,
  migrateInlineCommand,
  // from js
  migrateAudioCommand,
  // migrateBlogContentPlaceholderCommand,
  // migrateBlogLatestPostsCommand,
  // migrateBlogPostCategoriesCommand,
  // migrateBlogPostLIstingCommand,
  // migrateBlogPostContentPlaceholderCommand,
  migrateHorizontalLineCommand,
  migrateSalesRedirectionButtonCommand,
  migrateUpsellButtonsCommand,
  migrateMenuCommand,
  migrateContentBoxCommand,
  migrateLanguageSwitcherCommand,
  migrateContactUsRecaptchaElementCommand,
  migrateRowColumnCommand,
  migrateSectionCommand,
  migrateTwitterTweetButtonCommand,
  migrateCarouselCommand,
  migrateImageCommand,
  migrateHeadlineToTsCommand,
  migrateOfferPriceCommand,
  migratePaymentMethodCommand,
  migratePhysicalProductCommand,
  migrateTextToTsCommand,
  migrateVideoCommand,
  migrateOrderBumpCommand,
]
