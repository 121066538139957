import { migrateCommand } from 'tools/commands/migrate/utils/migrateCommand'
import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateAgreement } from './migrateAgreement'

export default function (page: ServerPage) {
  return migrateCommand({
    page,
    structureType: structureTypes.AGREEMENT,
    migrateFunction: migrateAgreement,
  })
}
