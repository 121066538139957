import structureTypes from 'common/constants/structureTypes'
import ServerPage from 'server/types/ServerPageInterface'
import { migrateCommand } from '../utils/migrateCommand'
import { migrateOrderBump } from './migrate-order-bump'

export default function (page: ServerPage) {
  return migrateCommand({
    page,
    structureType: structureTypes.ORDER_BUMP,
    migrateFunction: migrateOrderBump,
  })
}
