import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createRow } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-row'
import {
  migrateBackground,
  migrateBackgroundColor,
  migrateBoxShadow,
  migrateFullBorderType,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

export function migrateRow(entity: OldEntityInterface) {
  const row = createRow(entity.parentId, entity.masterBlockId)
  row.id = entity.id
  row.htmlAttrId = entity.options.attrId
  row.childIds = entity.childIds || []

  if (entity.options.appearance) {
    row.appearance.desktop = entity.options.appearance.desktop
    row.appearance.mobile = entity.options.appearance.mobile
  }

  if (entity.styles.padding) {
    row.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    row.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateMargin(row, entity)
  migrateMobileMargin(row, entity)
  migrateBackgroundColor(row, entity)
  migrateBackground(row, entity)
  migrateFullBorderType(row, entity)
  migrateBoxShadow(row, entity)

  if (entity.options.delay) {
    row.delay = entity.options.delay
  }

  if (entity.options.disableStacking) {
    row.disableStacking = entity.options.disableStacking
  }

  if (entity.options.reverseColumns) {
    row.reverseColumns = entity.options.reverseColumns
  }

  return row
}
