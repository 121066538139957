import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createProduct } from '../../../../client/pages/offer/entities/Product'
import {
  migrateFontSettings,
  migrateMargin,
  migrateMobileMargin,
} from '../utils/migrateUtils'

export function migratePhysicalProduct(
  entity: OldEntityInterface,
): EntityInterface {
  const newEntity = createProduct(entity.parentId, entity.masterBlockId)
  //id and masterBlock
  newEntity.id = entity.id
  if (entity.isMasterBlockRoot) {
    newEntity.isMasterBlockRoot = true
  }
  //margins
  migrateMargin(newEntity, entity)
  migrateMobileMargin(newEntity, entity)

  migrateFontSettings(newEntity, entity)

  //appearance
  if (entity.options.appearance) {
    newEntity.appearance.desktop = entity.options.appearance.desktop
    newEntity.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  newEntity.htmlAttrId = entity.options.attrId

  return newEntity
}
