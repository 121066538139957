import {
  migrateButtonFontSize,
  migrateButtonIcons,
  migrateButtonText,
  migrateButtonTextColor,
  migrateButtonWidth,
} from 'tools/commands/migrate/UpsellButton/migrateButtonUtils'
import {
  migrateAlignSelf,
  migrateBackgroundColor,
  migrateBoxShadow,
  migrateFullBorderType,
  migrateLineHeight,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { createPaymentButton } from 'client/components/entities/PaymentButton/PaymentButtonNew'

export function migratePaymentButton(
  entity: OldEntityInterface,
): PaymentButtonInterface {
  const newEntity = createPaymentButton(entity.parentId, entity.masterBlockId)

  newEntity.id = entity.id
  if (entity.isMasterBlockRoot) {
    newEntity.isMasterBlockRoot = true
  }

  migrateMargin(newEntity, entity)
  migrateMobileMargin(newEntity, entity)

  if (entity.styles.padding) {
    newEntity.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    newEntity.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateFullBorderType(newEntity, entity)
  migrateBoxShadow(newEntity, entity)
  migrateButtonText(newEntity, entity)
  migrateBackgroundColor(newEntity, entity)
  migrateButtonTextColor(newEntity, entity)
  migrateButtonWidth(newEntity, entity)
  migrateButtonFontSize(newEntity, entity)
  migrateLineHeight(newEntity, entity)
  migrateAlignSelf(newEntity, entity)
  migrateButtonIcons(newEntity, entity)

  if (entity.options.text) {
    newEntity.text = entity.options.text
  }

  if (entity.options.loadingText) {
    newEntity.loadingText = entity.options.loadingText
  }

  if (entity.options.subText) {
    newEntity.subText = entity.options.subText
  }

  if (entity.options.hover) {
    newEntity.hover = entity.options.hover
  }

  if (entity.mobileOptions.hover) {
    newEntity.mobileHover = entity.mobileOptions.hover
  }

  if (entity.options.appearance) {
    newEntity.appearance.desktop = entity.options.appearance.desktop
    newEntity.appearance.mobile = entity.options.appearance.mobile
  }

  newEntity.htmlAttrId = entity.options.attrId

  return newEntity
}
