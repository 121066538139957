export enum LibraryElementEnum {
  LibraryCarousel = 'LibraryCarousel',
  LibraryFaq = 'LibraryFaq',
  LibraryFacebookComments = 'LibraryFacebookComments',
  LibraryCountdown = 'LibraryCountdown',
  LibraryPricePlanCalculator = 'LibraryPricePlanCalculator',
  LibraryWebinarRegistrationDate = 'LibraryWebinarRegistrationDate',
  LibraryText = 'LibraryText',
  LibraryHeadline = 'LibraryHeadline',
  LibraryCustomLogin = 'LibraryCustomLogin',
  LibraryBlogPostImage = 'LibraryBlogPostImage',
  LibraryContentTable = 'LibraryContentTable',
  LibraryBreadcrumbs = 'LibraryBreadcrumbs',
  LibraryContactUs = 'LibraryContactUs',
  LibraryOrderSummary = 'LibraryOrderSummary',
  LibraryBlogPostDate = 'LibraryBlogPostDate',
  LibraryBlogPostTitle = 'LibraryBlogPostTitle',
  LibrarySurvey = 'LibrarySurvey',
  LibraryUpsellAgreeButton = 'LibraryUpsellAgreeButton',
  LibraryUpsellDisagreeButton = 'LibraryUpsellDisagreeButton',
  LibraryMenu = 'LibraryMenu',
  LibraryImage = 'LibraryImage',
  LibraryLanguageSwitcher = 'LibraryLanguageSwitcher',
  LibraryBlogPostCategories = 'LibraryBlogPostCategories',
  LibraryBlogContentPlaceholder = 'LibraryBlogContentPlaceholder',
  LibraryBlogPostBody = 'LibraryBlogPostBody',
  LibraryBlogPostListing = 'LibraryBlogPostListing',
  LibraryBlogLatestPosts = 'LibraryBlogLatestPosts',
  LibraryVideo = 'LibraryVideo',
  LibraryHorizontalLine = 'LibraryHorizontalLine',
  LibraryBlogCategoryTitle = 'LibraryBlogCategoryTitle',
  LibraryBlogCategoryDescription = 'LibraryBlogCategoryDescription',
  LibraryWebinarSessionLink = 'LibraryWebinarSessionLink',
  LibraryWebinarSessionVideo = 'LibraryWebinarSessionVideo',
  LibraryWebinarSessionDateTime = 'LibraryWebinarSessionDateTime',
  LibraryOfferPrice = 'LibraryOfferPrice',
  LibraryLatestBlogPostsCarousel = 'LibraryLatestBlogPostsCarousel',
  LibraryOrderBump = 'LibraryOrderBump',
  LibraryAudio = 'LibraryAudio',
  LibraryProduct = 'LibraryProduct',
  LibraryWebinarCallToAction = 'LibraryWebinarCallToAction',
  LibrarySalesRedirectionButton = 'LibrarySalesRedirectionButton',
  LibraryOptInRecaptcha = 'LibraryOptInRecaptcha',
  LibraryTwitterTweetButton = 'LibraryTwitterTweetButton',
  LibraryCustomerType = 'LibraryCustomerType',
  LibraryContentBox = 'LibraryContentBox',
  LibraryRow = 'LibraryRow',
  LibraryColumn6 = 'LibraryColumn6',
  LibraryColumn4 = 'LibraryColumn4',
  LibraryColumn3 = 'LibraryColumn3',
  LibraryPaymentMethod = 'LibraryPaymentMethod',
  LibrarySection = 'LibrarySection',
  LibraryRawHTML = 'LibraryRawHTML',
  LibraryCheckbox = 'LibraryCheckbox',
  LibraryShippingFees = 'LibraryShippingFees',
  LibraryBulletList = 'LibraryBulletList',
  LibraryPaymentButton = 'LibraryPaymentButton',
  LibraryCalendar = 'LibraryCalendar',
  LibraryAgreement = 'LibraryAgreement',
}

export default LibraryElementEnum
