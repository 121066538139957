import React from 'react'
import { pageTypes } from 'common/constants/pageTypes'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { InlineBodyInterface } from 'common/types/inline-body-interface'
import { getMobileBackgroundFileId } from 'common/utils/getMobileBackgroundFileId'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getRootEntity } from 'client/reducers/pageReducer'
import { usePage } from 'client/store'
import { checkIsEntityMigrated } from 'client/store/page/pageSelectors'
import Range from '../components/Range/Range'
import { useUpdateProp } from '../hooks/useUpdateProps'
import { LanguageSelector, Width } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { BackgroundPositionStyleNew, ShadowStyle } from '../styles'
import Border from '../styles/Border'
import PaddingStyle from '../styles/SplitPaddingStyle'
import InlineFormSettings from './InlineFormSettings'
import Typography from './components/Typography'

export const InlineSettingsResolver = () => {
  const isMigratedInlinePage = usePage(page =>
    checkIsEntityMigrated(
      page,
      [structureTypes.INLINE, EntityTypeEnum.InlineBody],
      EntityTypeEnum.InlineBody,
    ),
  )

  if (isMigratedInlinePage) {
    return <InlineBodySettings />
  }
  return <InlineFormSettings />
}

function InlineBodySettings() {
  const entity: InlineBodyInterface = usePage(page =>
    getRootEntity({ ...page, type: pageTypes.inlineBody }),
  )

  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()

  return (
    <React.Fragment>
      <Width
        width={entity.maxWidth}
        update={value => updateProp('maxWidth')(parseInt(value))}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        border={isMobile ? entity.mobileBorder : entity.border}
        update={isMobile ? updateProp('mobileBorder') : updateProp('border')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Typography />
      {/* @ts-expect-error */}
      <LanguageSelector />
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        label="settings_styles.background_color.label"
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
      />
      <BackgroundPositionStyleNew
        backgroundPosition={entity.background}
        fileId={
          isMobile
            ? getMobileBackgroundFileId(
                entity.backgroundFileId,
                entity.mobileBackgroundFileId,
              )
            : entity.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateProp('mobileBackgroundFileId')
            : updateProp('backgroundFileId')
        }
        update={updateProp('background')}
      />
      <Range
        label="page_settings.body.background_blur.label"
        value={entity.blur}
        change={updateProp('blur')}
        max={10}
      />
    </React.Fragment>
  )
}

export default InlineBodySettings
