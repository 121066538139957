import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import { OrderBumpInterface } from 'common/types/entities/order-bump-interface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import CustomerTypeSettings from './settings/CustomerTypeSettings'
import OfferPriceSettings from './settings/OfferPriceSettings'
import PaymentMethodSettings from './settings/PaymentMethodSettings'
import OrderBumpSettings from './settings/order-bump-settings'

function createSettingsForOfferPage(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.OfferPrice:
      return <OfferPriceSettings entity={entity as OfferPriceInterface} />
    case EntityTypeEnum.CustomerType:
      return <CustomerTypeSettings entity={entity as CustomerTypeInterface} />
    case EntityTypeEnum.PaymentMethod:
      return <PaymentMethodSettings entity={entity as PaymentMethodInterface} />
    case EntityTypeEnum.OrderBump:
      return <OrderBumpSettings entity={entity as OrderBumpInterface} />
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForOfferPage
