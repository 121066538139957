import { StickyPositionEnum } from 'common/components/entities/Section/enums/StickyPositionEnum'
import { StickyTypeEnum } from 'common/components/entities/Section/enums/StickyTypeEnum'
import { CommonWidthEnum } from 'common/constants/commonWidth'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createSection } from 'client/components/entities/Section/utils/createSection'
import {
  migrateBackground,
  migrateBackgroundColor,
  migrateBoxShadow,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

interface OldSectionInterface extends OldEntityInterface {
  containerBackgroundColor?: string
}

export function migrateSection(entity: OldSectionInterface) {
  const section = createSection(entity.parentId, entity.masterBlockId)
  section.id = entity.id
  //attrId
  if (entity.options.attrId) {
    section.htmlAttrId = entity.options.attrId
  }

  //appearance
  if (entity.options.appearance) {
    section.appearance.desktop = entity.options.appearance.desktop
    section.appearance.mobile = entity.options.appearance.mobile
  }

  //delay
  if (entity.options.delay) {
    section.delay = entity.options.delay
  }

  //contentWidth
  if (entity.options.width) {
    if (entity.options.width === 'settings_styles.content_width.medium') {
      section.contentWidth = CommonWidthEnum.fullPage
    } else {
      section.contentWidth = entity.options.width
    }
  }

  section.childIds = entity.childIds || []

  //padding
  if (entity.styles.padding) {
    section.padding = migratePadding(entity.styles.padding)
  }

  //margin
  migrateMargin(section, entity)

  //sticky
  if (entity.styles.position === 'sticky') {
    if (entity.styles.top !== 'auto') {
      section.sticky = {
        position: StickyPositionEnum.top,
        indent: parseInt(entity.styles.top),
        type: StickyTypeEnum.Sticky,
      }
    } else if (entity.styles.bottom !== 'auto') {
      section.sticky = {
        position: StickyPositionEnum.bottom,
        indent: parseInt(entity.styles.bottom),
        type: StickyTypeEnum.Sticky,
      }
    }
  }

  //mobilePadding
  if (entity.mobileStyles.padding) {
    section.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  //mobileMargin
  migrateMobileMargin(section, entity)

  //mobileSticky
  if (entity.mobileStyles.position === 'sticky') {
    if (entity.mobileStyles.top !== 'auto') {
      section.mobileSticky = {
        position: StickyPositionEnum.top,
        indent: parseInt(entity.mobileStyles.top),
        type: StickyTypeEnum.Sticky,
      }
    } else if (entity.mobileStyles.bottom !== 'auto') {
      section.mobileSticky = {
        position: StickyPositionEnum.bottom,
        indent: parseInt(entity.mobileStyles.bottom),
        type: StickyTypeEnum.Sticky,
      }
    }
  }

  //backgroundColor
  migrateBackgroundColor(section, entity)

  //boxShadow
  migrateBoxShadow(section, entity)

  //background
  migrateBackground(section, entity)

  //containerBackgroundColor
  if (entity.options && entity.options.containerBackgroundColor) {
    section.containerBackgroundColor = entity.options.containerBackgroundColor
  }
  //mobileContainerBackgroundColor
  if (entity.mobileOptions && entity.mobileOptions.containerBackgroundColor) {
    section.mobileContainerBackgroundColor =
      entity.mobileOptions.containerBackgroundColor
  }

  return section
}
