import videoTypes from 'common/constants/videoTypes'
import { OldVideoInterface } from 'common/types/entities/OldEntityInterface'
import { createVideo } from 'client/components/entities/VideoNew'
import {
  migrateFullBorderType,
  migrateMargin,
  migrateMobileMargin,
} from '../utils/migrateUtils'

export function migrateVideo(originalVideo: OldVideoInterface) {
  const video = createVideo(originalVideo.parentId, originalVideo.masterBlockId)
  delete video.url // delete default video
  video.id = originalVideo.id
  video.htmlAttrId = originalVideo.options.attrId
  if (originalVideo.isMasterBlockRoot) {
    originalVideo.isMasterBlockRoot = true
  }
  //margins
  migrateMargin(video, originalVideo)
  migrateMobileMargin(video, originalVideo)

  if (
    originalVideo.options.delay &&
    !isNaN(parseInt(originalVideo.options.delay))
  ) {
    video.delay = parseInt(originalVideo.options.delay)
  }

  if (originalVideo.options.appearance) {
    video.appearance.desktop = originalVideo.options.appearance.desktop
    video.appearance.mobile = originalVideo.options.appearance.mobile
  }

  migrateFullBorderType(video, originalVideo)
  if (originalVideo.styles.boxShadow) {
    video.boxShadow = originalVideo.styles.boxShadow
  }

  if (originalVideo.mobileStyles.boxShadow) {
    video.mobileBoxShadow = originalVideo.mobileStyles.boxShadow
  }

  if (originalVideo.options.autoPlay) {
    video.autoplay = Boolean(originalVideo.options.autoPlay) // I remember we had "1" in the past
  }

  if (originalVideo.options.controls) {
    video.controls = Boolean(originalVideo.options.controls) // I remember we had "1" in the past
  }

  if (originalVideo.options.type === videoTypes.YOUTUBE) {
    video.url = originalVideo.options.youtubeUrl
  } else if (originalVideo.options.type === videoTypes.URL) {
    video.url = originalVideo.options.url
  } else if (originalVideo.options.type === videoTypes.FILE) {
    video.fileId = originalVideo.options.srcFileId
  } else if (originalVideo.options.type === videoTypes.CUSTOM) {
    video.embedCode = originalVideo.options.embedIframeTag
  }

  return video
}
