import { AUTOMIGRATE_COMMAND_LIST } from 'client/const/migrate-consts'
import ServerPage from 'server/types/ServerPageInterface'

const migratePage = (page: ServerPage): ServerPage =>
  AUTOMIGRATE_COMMAND_LIST.reduce(
    (page, command) => command(page)[0] as ServerPage,
    page,
  )

export default migratePage
