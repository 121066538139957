import { migratePaymentButton } from 'tools/commands/migrate/paymentButton/migratePaymentButton'
import structureTypes from 'common/constants/structureTypes'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import ServerPage from 'server/types/ServerPageInterface'

export default function (page: ServerPage) {
  let isChanged = false
  Object.values(page.content.entities).forEach(entity => {
    if (entity.type === structureTypes.PAYMENT_BUTTON) {
      isChanged = true
      page.content.entities[entity.id] = migratePaymentButton(
        entity as OldEntityInterface,
      )
    }
  })

  return [page, isChanged]
}
