import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createTwitterTweetButton } from 'client/components/entities/TwitterTweetButton/utils/create-twitter-tweet-button'
import { migrateMargin, migrateMobileMargin } from '../utils/migrateUtils'

export function migrateTwitterTweetButton(entity: OldEntityInterface) {
  const twitterTweetButton = createTwitterTweetButton(
    entity.parentId,
    entity.masterBlockId,
  )
  twitterTweetButton.id = entity.id
  twitterTweetButton.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    twitterTweetButton.appearance.desktop = entity.options.appearance.desktop
    twitterTweetButton.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(twitterTweetButton, entity)
  migrateMobileMargin(twitterTweetButton, entity)

  if (entity.options.text) {
    twitterTweetButton.text = entity.options.text
  }

  if (entity.styles.alignSelf) {
    twitterTweetButton.alignSelf = entity.styles.alignSelf
  }

  if (entity.mobileStyles.alignSelf) {
    twitterTweetButton.mobileAlignSelf = entity.mobileStyles.alignSelf
  }

  return twitterTweetButton
}
