import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { InlineBodyInterface } from 'common/types/inline-body-interface'
import {
  migrateBackground,
  migrateBoxShadow,
  migrateFullBorderType,
  migratePadding,
} from '../utils/migrateUtils'

export function migrateInline(entity: OldEntityInterface) {
  const inline: InlineBodyInterface = {} as InlineBodyInterface

  inline.id = entity.id
  inline.type = EntityTypeEnum.InlineBody
  inline.childIds = entity.childIds!

  inline.htmlAttrId = entity.options.attrId
  inline.title = entity.options.title

  inline.width = parseInt(entity.styles.width)
  inline.mobileWidth = parseInt(entity.mobileStyles.width)
  inline.maxWidth = parseInt(entity.styles.maxWidth)

  if (entity.options.blur) {
    inline.blur = entity.options.blur
  }

  if (entity.styles.padding) {
    inline.padding = migratePadding(entity.styles.padding)
  }
  if (entity.mobileStyles.padding) {
    inline.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  if (entity.styles.backgroundColor) {
    inline.backgroundColor = entity.styles.backgroundColor
  }
  if (entity.mobileStyles.backgroundColor) {
    inline.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }

  inline.background = {}
  migrateBackground(inline, entity)
  inline.mobileBackground = {}
  if (entity.mobileStyles.backgroundSize) {
    inline.mobileBackground.backgroundSize = entity.mobileStyles.backgroundSize
  }
  if (entity.styles.backgroundPosition) {
    inline.mobileBackground.backgroundPosition =
      entity.mobileStyles.backgroundPosition
  }
  if (entity.styles.backgroundRepeat) {
    inline.mobileBackground.backgroundRepeat =
      entity.mobileStyles.backgroundRepeat
  }
  if (entity.styles.backgroundAttachment) {
    inline.mobileBackground.backgroundAttachment =
      entity.mobileStyles.backgroundAttachment
  }

  migrateFullBorderType(inline, entity)
  migrateBoxShadow(inline, entity)

  return inline
}
