import React from 'react'
import { pageTypes } from 'common/constants/pageTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import BlogLayoutSettings from './BlogLayoutSettings'
import BlogPageSettings from './BlogPageSettings'
import BlogPostLayoutSettings from './BlogPostLayoutSettings'
import BlogPostSettings from './BlogPostSettings'
import CommonPageSettings from './CommonPageSettings'
import CommonWithoutSeoSettings from './CommonWithoutSeoSettings'
import LectureSettings from './LectureSettings'
import PopupFormSettings from './PopupFormSettings'
import { InlineSettingsResolver } from './inline-body-settings'

export function createPageSettings(pageType) {
  switch (pageType) {
    case pageTypes.blogLayout:
      return <BlogLayoutSettings />
    case pageTypes.blogPostLayout:
      return <BlogPostLayoutSettings />
    case pageTypes.blogPostBody:
      return <BlogPostSettings />
    case pageTypes.blogHome:
    case pageTypes.blogStatic:
      return <BlogPageSettings />
    case pageTypes.popup:
      return <PopupFormSettings />
    case pageTypes.inline:
      return <InlineSettingsResolver />
    case pageTypes.webinarSession:
    case pageTypes.customLogin:
    case pageTypes.courseIntroduction:
    case PageTypeEnum.NotFound:
      return <CommonWithoutSeoSettings />
    case pageTypes.lecture:
      return <LectureSettings />
    default:
      return <CommonPageSettings />
  }
}
