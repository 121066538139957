import React from 'react'
import CommonOrderBump from 'common/components/entities/OrderBumpNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { OrderBumpInterface } from 'common/types/entities/order-bump-interface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { usePayment } from 'client/store'
import { getOfferBump } from 'client/store/payment/paymentSelectors'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

function OrderBump({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<OrderBumpInterface>) {
  const bump = usePayment(getOfferBump)
  const { id, type, childIds, htmlAttrId, ...restProps } = entity

  const renderBump = () => (
    <CommonOrderBump {...restProps} attrId={htmlAttrId}>
      <ChildrenEntities
        id={entity.id}
        childIds={entity.childIds}
        type={entity.type}
      />
    </CommonOrderBump>
  )

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      {bump ? (
        renderBump()
      ) : (
        <EntityExampleWrapper label="entities.order_bump.warnings.no_price_plan">
          {renderBump()}
        </EntityExampleWrapper>
      )}
    </BaseEntityNew>
  )
}

export function createOrderBump(
  parentId: string,
  title: string,
  masterBlockId?: string,
): OrderBumpInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.OrderBump, parentId, masterBlockId),
    type: EntityTypeEnum.OrderBump,
    fontSize: 24,
    lineHeight: 59,
    fontWeight: 'bold',
    childIds: [],
    title,
    color: 'rgba(68, 149, 49, 1)',
    headerBackgroundColor: 'rgba(254, 250, 169, 1)',
    backgroundColor: 'rgba(251, 248, 230, 1)',
    justifyContent: 'center',
    border: {
      style: 'dashed',
      width: 3,
      color: 'rgba(0, 0, 0, 1)',
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 15,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 8,
      paddingRight: 8,
      paddingBottom: 8,
      paddingLeft: 8,
    },
  }
}

export default OrderBump
