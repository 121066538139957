import audioTypes from 'common/constants/audioTypes'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createAudio } from 'client/components/entities/Audio/AudioNew'
import { migrateMargin, migrateMobileMargin } from '../utils/migrateUtils'

export function migrateAudio(originalAudio: OldEntityInterface) {
  const audio = createAudio(originalAudio.parentId, originalAudio.masterBlockId)
  audio.id = originalAudio.id
  audio.htmlAttrId = originalAudio.options.attrId
  if (originalAudio.isMasterBlockRoot) {
    originalAudio.isMasterBlockRoot = true
  }
  //margins
  migrateMargin(audio, originalAudio)
  migrateMobileMargin(audio, originalAudio)

  if (originalAudio.options.appearance) {
    audio.appearance.desktop = originalAudio.options.appearance.desktop
    audio.appearance.mobile = originalAudio.options.appearance.mobile
  }

  if (originalAudio.options.type === audioTypes.URL) {
    audio.url = originalAudio.options.url
  } else if (originalAudio.options.type === audioTypes.FILE) {
    audio.fileId = originalAudio.options.srcFileId
  }

  return audio
}
