import { BackgroundInterface } from 'common/types/BackgroundType'
import EntityInterface, {
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import {
  AlignSelfType,
  AlignType,
  FullBorderRadiusType,
} from 'common/types/styleTypes'
import { MarginType } from 'common/types/styleTypes'

export interface UserBlock {
  content: (OldEntityInterface | EntityInterface | EntityInnerItemInterface)[]
}

export function migrateMobileMargin(
  newEntity: EntityInterface,
  originalEntity: OldEntityInterface,
) {
  if (!originalEntity.mobileStyles) {
    newEntity.mobileMargin = {}
    return
  }

  if (
    originalEntity.mobileStyles.marginTop &&
    !isNaN(parseInt(originalEntity.mobileStyles.marginTop))
  ) {
    newEntity.mobileMargin.marginTop = parseInt(
      originalEntity.mobileStyles.marginTop,
    )
  }
  if (
    originalEntity.mobileStyles.marginRight &&
    !isNaN(parseInt(originalEntity.mobileStyles.marginRight))
  ) {
    newEntity.mobileMargin.marginRight = parseInt(
      originalEntity.mobileStyles.marginRight,
    )
  }
  if (
    originalEntity.mobileStyles.marginBottom &&
    !isNaN(parseInt(originalEntity.mobileStyles.marginBottom))
  ) {
    newEntity.mobileMargin.marginBottom = parseInt(
      originalEntity.mobileStyles.marginBottom,
    )
  }
  if (
    originalEntity.mobileStyles.marginLeft &&
    !isNaN(parseInt(originalEntity.mobileStyles.marginLeft))
  ) {
    newEntity.mobileMargin.marginLeft = parseInt(
      originalEntity.mobileStyles.marginLeft,
    )
  }
}

export function migrateMargin(
  newEntity: EntityInterface,
  originalEntity: OldEntityInterface,
) {
  if (
    originalEntity.styles.marginTop &&
    !isNaN(parseInt(originalEntity.styles.marginTop))
  ) {
    newEntity.margin.marginTop = parseInt(originalEntity.styles.marginTop)
  } else {
    newEntity.margin.marginTop = 0
  }
  if (
    originalEntity.styles.marginRight &&
    !isNaN(parseInt(originalEntity.styles.marginRight))
  ) {
    newEntity.margin.marginRight = parseInt(originalEntity.styles.marginRight)
  } else {
    newEntity.margin.marginRight = 0
  }
  if (
    originalEntity.styles.marginBottom &&
    !isNaN(parseInt(originalEntity.styles.marginBottom))
  ) {
    newEntity.margin.marginBottom = parseInt(originalEntity.styles.marginBottom)
  } else {
    newEntity.margin.marginBottom = 0
  }
  if (
    originalEntity.styles.marginLeft &&
    !isNaN(parseInt(originalEntity.styles.marginLeft))
  ) {
    newEntity.margin.marginLeft = parseInt(originalEntity.styles.marginLeft)
  } else {
    newEntity.margin.marginLeft = 0
  }
}

export function migrateMarginNew(
  {
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  }: {
    marginTop?: string
    marginRight?: string
    marginBottom?: string
    marginLeft?: string
  },
  fallbackMargin: MarginType,
) {
  const migratedMargins: MarginType = fallbackMargin

  if (marginTop && !isNaN(parseInt(marginTop))) {
    migratedMargins.marginTop = parseInt(marginTop)
  }
  if (marginRight && !isNaN(parseInt(marginRight))) {
    migratedMargins.marginRight = parseInt(marginRight)
  }
  if (marginBottom && !isNaN(parseInt(marginBottom))) {
    migratedMargins.marginBottom = parseInt(marginBottom)
  }
  if (marginLeft && !isNaN(parseInt(marginLeft))) {
    migratedMargins.marginLeft = parseInt(marginLeft)
  }

  return migratedMargins
}

const PADDING_LEFT = 3
const PADDING_RIGHT = 1
const PADDING_TOP = 0
const PADDING_BOTTOM = 2

export function migratePadding(padding: string) {
  const paddingArr = padding.split(' ')

  return {
    paddingTop: paddingArr[PADDING_TOP]
      ? parseInt(paddingArr[PADDING_TOP], 10)
      : 0,
    paddingRight: paddingArr[PADDING_RIGHT]
      ? parseInt(paddingArr[PADDING_RIGHT], 10)
      : 0,
    paddingBottom: paddingArr[PADDING_BOTTOM]
      ? parseInt(paddingArr[PADDING_BOTTOM], 10)
      : 0,
    paddingLeft: paddingArr[PADDING_LEFT]
      ? parseInt(paddingArr[PADDING_LEFT], 10)
      : 0,
  }
}

export interface FontInterface {
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  fontFileId?: number
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  mobileFontFileId?: number
}

export function migrateFontSettings(
  newEntity: FontInterface,
  originalEntity: OldEntityInterface,
) {
  //text
  if (originalEntity.styles.fontFamily) {
    newEntity.fontFamily = originalEntity.styles.fontFamily
  }
  if (originalEntity.styles.fontWeight) {
    newEntity.fontWeight = originalEntity.styles.fontWeight
  }
  if (originalEntity.styles.fontStyle) {
    newEntity.fontStyle = originalEntity.styles.fontStyle
  }
  //mobileText
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.fontFamily) {
    newEntity.mobileFontFamily = originalEntity.mobileStyles.fontFamily
  }
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.fontWeight) {
    newEntity.mobileFontWeight = originalEntity.mobileStyles.fontWeight
  }
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.fontStyle) {
    newEntity.mobileFontStyle = originalEntity.mobileStyles.fontStyle
  }
}

export interface FontSizeInterface {
  fontSize: number
  mobileFontSize?: number
}

export function migrateFontSize(
  newEntity: FontSizeInterface,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.styles.fontSize &&
    !isNaN(parseInt(originalEntity.styles.fontSize))
  ) {
    newEntity.fontSize = parseInt(originalEntity.styles.fontSize)
  }
  if (
    originalEntity.mobileStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileStyles.fontSize))
  ) {
    newEntity.mobileFontSize = parseInt(originalEntity.mobileStyles.fontSize)
  }
}
export interface BoxShadowInterface {
  boxShadow?: string
  mobileBoxShadow?: string
}

export function migrateBoxShadow(
  newEntity: BoxShadowInterface,
  originalEntity: OldEntityInterface,
) {
  //boxShadow
  if (originalEntity.styles.boxShadow) {
    newEntity.boxShadow = originalEntity.styles.boxShadow
  }

  //mobileBoxShadow
  if (originalEntity.mobileStyles.boxShadow) {
    newEntity.mobileBoxShadow = originalEntity.mobileStyles.boxShadow
  }
}

export interface AlignSelfInterface {
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

export function migrateAlignSelf(
  newEntity: AlignSelfInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.styles.alignSelf) {
    newEntity.alignSelf = originalEntity.styles.alignSelf
  }

  //mobileAlignSelf
  if (originalEntity.mobileStyles.alignSelf) {
    newEntity.mobileAlignSelf = originalEntity.mobileStyles.alignSelf
  }
}

export interface LineHeightInterface {
  lineHeight?: number
  mobileLineHeight?: number
}

export function migrateLineHeight(
  newEntity: LineHeightInterface,
  originalEntity: OldEntityInterface,
) {
  //lineHeight
  if (
    originalEntity.styles.lineHeight &&
    !isNaN(parseInt(originalEntity.styles.lineHeight))
  ) {
    newEntity.lineHeight = parseInt(originalEntity.styles.lineHeight)
  }

  //mobileLineHeight
  if (
    originalEntity.mobileStyles.lineHeight &&
    !isNaN(parseInt(originalEntity.mobileStyles.lineHeight))
  ) {
    newEntity.mobileLineHeight = parseInt(
      originalEntity.mobileStyles.lineHeight,
    )
  }
}

export interface BackgroundColorInterface {
  backgroundColor?: string
  mobileBackgroundColor?: string
}

export function migrateBackgroundColor(
  newEntity: BackgroundColorInterface,
  originalEntity: OldEntityInterface,
) {
  //backgroundColor
  if (originalEntity.styles.backgroundColor) {
    newEntity.backgroundColor = originalEntity.styles.backgroundColor
  }
  //mobileBackgroundColor
  if (originalEntity.mobileStyles.backgroundColor) {
    newEntity.mobileBackgroundColor =
      originalEntity.mobileStyles.backgroundColor
  }
}

export function migrateBackground(
  newEntity: BackgroundInterface,
  originalEntity: OldEntityInterface,
) {
  //backgroundFileId
  if (originalEntity.options.backgroundFileId !== undefined) {
    newEntity.backgroundFileId = originalEntity.options.backgroundFileId
  }
  //mobileBackgroundFileId
  if (originalEntity.mobileOptions.backgroundFileId !== undefined) {
    newEntity.mobileBackgroundFileId =
      originalEntity.mobileOptions.backgroundFileId
  }
  //backgroundSize
  if (originalEntity.styles.backgroundSize) {
    newEntity.background.backgroundSize = originalEntity.styles.backgroundSize
  }
  //backgroundPosition
  if (originalEntity.styles.backgroundPosition) {
    newEntity.background.backgroundPosition =
      originalEntity.styles.backgroundPosition
  }
  //backgroundRepeat
  if (originalEntity.styles.backgroundRepeat) {
    newEntity.background.backgroundRepeat =
      originalEntity.styles.backgroundRepeat
  }
  //backgroundAttachment
  if (originalEntity.styles.backgroundAttachment) {
    newEntity.background.backgroundAttachment =
      originalEntity.styles.backgroundAttachment
  }
}

export function migrateFullBorderType(
  newEntity: any,
  originalEntity: OldEntityInterface,
) {
  //borderStyle
  if (
    typeof originalEntity.styles.borderStyle === 'undefined' ||
    originalEntity.styles.borderStyle === 'none'
  ) {
    // delete newEntity.border
    // do nothing
  } else {
    if (!newEntity.border) {
      newEntity.border = {}
    }

    newEntity.border.style = originalEntity.styles.borderStyle
    //borderWidth
    if (
      originalEntity.styles.borderWidth &&
      !isNaN(parseInt(originalEntity.styles.borderWidth))
    ) {
      newEntity.border.width = parseInt(originalEntity.styles.borderWidth)
    }
    //borderType
    if (originalEntity.options.borderType) {
      newEntity.border.type = originalEntity.options.borderType
    }
    //borderColor
    if (originalEntity.styles.borderColor) {
      newEntity.border.color = originalEntity.styles.borderColor
    }
  }

  const desktopBorderRadius = {} as FullBorderRadiusType
  //borderRadius
  if (
    originalEntity.styles.borderTopLeftRadius &&
    !isNaN(parseInt(originalEntity.styles.borderTopLeftRadius))
  ) {
    desktopBorderRadius.borderTopLeftRadius = parseInt(
      originalEntity.styles.borderTopLeftRadius,
    )
  }

  if (
    originalEntity.styles.borderTopRightRadius &&
    !isNaN(parseInt(originalEntity.styles.borderTopRightRadius))
  ) {
    desktopBorderRadius.borderTopRightRadius = parseInt(
      originalEntity.styles.borderTopRightRadius,
    )
  }
  if (
    originalEntity.styles.borderBottomLeftRadius &&
    !isNaN(parseInt(originalEntity.styles.borderBottomLeftRadius))
  ) {
    desktopBorderRadius.borderBottomLeftRadius = parseInt(
      originalEntity.styles.borderBottomLeftRadius,
    )
  }
  if (
    originalEntity.styles.borderBottomRightRadius &&
    !isNaN(parseInt(originalEntity.styles.borderBottomRightRadius))
  ) {
    desktopBorderRadius.borderBottomRightRadius = parseInt(
      originalEntity.styles.borderBottomRightRadius,
    )
  }
  if (Object.keys(desktopBorderRadius).length > 0) {
    if (!newEntity.border) {
      newEntity.border = {}
    }
    newEntity.border.radius = desktopBorderRadius
  }

  if (!originalEntity.mobileStyles) {
    return
  }
  // MOBILE BORDER

  //borderStyle
  if (
    typeof originalEntity.mobileStyles.borderStyle === 'undefined' ||
    originalEntity.mobileStyles.borderStyle === 'none'
  ) {
    // delete newEntity.mobileBorder
    // do nothing
  } else {
    if (!newEntity.mobileBorder) {
      newEntity.mobileBorder = {}
    }
    newEntity.mobileBorder.style = originalEntity.mobileStyles.borderStyle
    //borderWidth
    if (
      originalEntity.mobileStyles.borderWidth &&
      !isNaN(parseInt(originalEntity.mobileStyles.borderWidth))
    ) {
      newEntity.mobileBorder.width = parseInt(
        originalEntity.mobileStyles.borderWidth,
      )
    }
    //borderType
    if (
      originalEntity.mobileOptions &&
      originalEntity.mobileOptions.borderType
    ) {
      newEntity.mobileBorder.type =
        originalEntity.mobileOptions && originalEntity.mobileOptions.borderType
    }
    //borderColor
    if (originalEntity.mobileStyles.borderColor) {
      newEntity.mobileBorder.color = originalEntity.mobileStyles.borderColor
    }
  }

  const mobileBorderRadius = {} as FullBorderRadiusType
  // const mobileBorder = newEntity.mobileBorder as Partial<BorderType>
  // const mobileBorderRadius = mobileBorder.radius as FullBorderRadiusType
  //borderRadius
  if (
    originalEntity.mobileStyles.borderTopLeftRadius &&
    !isNaN(parseInt(originalEntity.mobileStyles.borderTopLeftRadius))
  ) {
    mobileBorderRadius.borderTopLeftRadius = parseInt(
      originalEntity.mobileStyles.borderTopLeftRadius,
    )
  }
  if (
    originalEntity.mobileStyles.borderTopRightRadius &&
    !isNaN(parseInt(originalEntity.mobileStyles.borderTopRightRadius))
  ) {
    mobileBorderRadius.borderTopRightRadius = parseInt(
      originalEntity.mobileStyles.borderTopRightRadius,
    )
  }
  if (
    originalEntity.mobileStyles.borderBottomLeftRadius &&
    !isNaN(parseInt(originalEntity.mobileStyles.borderBottomLeftRadius))
  ) {
    mobileBorderRadius.borderBottomLeftRadius = parseInt(
      originalEntity.mobileStyles.borderBottomLeftRadius,
    )
  }
  if (
    originalEntity.mobileStyles.borderBottomRightRadius &&
    !isNaN(parseInt(originalEntity.mobileStyles.borderBottomRightRadius))
  ) {
    mobileBorderRadius.borderBottomRightRadius = parseInt(
      originalEntity.mobileStyles.borderBottomRightRadius,
    )
  }

  if (Object.keys(mobileBorderRadius).length > 0) {
    if (!newEntity.mobileBorder) {
      newEntity.mobileBorder = {}
    }
    newEntity.mobileBorder.radius = mobileBorderRadius
  }
}

export function migrateJustifyContentToTextAlign(
  justifyContent: AlignSelfType,
): AlignType {
  switch (justifyContent) {
    case 'flex-start':
      return 'left'
    case 'center':
      return 'center'
    case 'flex-end':
      return 'right'
  }
}
