import React from 'react'
import { OrderBumpInterface } from 'common/types/entities/order-bump-interface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { FontSize } from 'client/components/core/Sidebar/components/Settings/options'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { ShadowStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import FlexAlignStyle from 'client/components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

function OrderBumpSettings({ entity }: { entity: OrderBumpInterface }) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<OrderBumpInterface>()

  const toggleArrowVisibility = () => {
    updateEntity({ ...entity, isArrowHidden: !entity.isArrowHidden })
  }

  return (
    <>
      <Input
        label="entity_settings.order_bump.title"
        value={entity.title}
        update={updateProp('title')}
      />
      <FlexAlignStyle
        align={entity.justifyContent}
        mobileAlign={entity.mobileJustifyContent || entity.justifyContent}
        update={updateProp('justifyContent')}
        mobileUpdate={updateProp('mobileJustifyContent')}
      />
      <NewCheckbox
        checked={!entity.isArrowHidden}
        update={toggleArrowVisibility}
        labelText="entity_settings.order_bump.show_arrow"
      />
      <ColorPicker
        label="settings_styles.header_background_color.label"
        color={entity.headerBackgroundColor}
        mobileColor={
          entity.mobileHeaderBackgroundColor || entity.headerBackgroundColor
        }
        update={updateProp('headerBackgroundColor')}
        mobileUpdate={updateProp('mobileHeaderBackgroundColor')}
      />
      <ColorPicker
        label="settings_styles.background_color.label"
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor || entity.backgroundColor}
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
      />
      <ColorPicker
        update={updateProp('color')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        mobileUpdate={updateProp('mobileColor')}
        label="global_settings.text_color.label"
      />
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize || entity.fontSize}
      />
      <TextFontUpdater entity={entity} update={updateEntity} />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow || entity.boxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder || entity.border : entity.border}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={
          isMobile ? entity.mobilePadding || entity.padding : entity.padding
        }
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default OrderBumpSettings
