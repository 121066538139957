import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux'
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer,
} from 'redux'
import reduxThunk from 'redux-thunk'
import { localeValues } from 'common/constants/localeTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { OfferInterface, OneShotPlan } from 'common/types/OfferInterface'
import { SeoInterface, GlobalSettingsInterface } from 'common/types/Page'
import { BaseEntityInterface } from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { ValueOf } from 'common/types/utilityTypes'
import { selectors as managementSelectors } from 'client/reducers/managementReducer'
import { selectors as pageSelectors } from 'client/reducers/pageReducer'
import typedManagementSelectors from 'client/store/management/managementSelectors'
import { removeLostRulesMiddleware } from 'client/store/middlewares/removeLostRulesMiddleware'
import typedPageSelectors from 'client/store/page/pageSelectors'
import typedPaymentSelectors from 'client/store/payment/paymentSelectors'
import { PaymentMethodEnum } from '../../common/enums/PaymentMethodEnum'
import { reducers as staticReducers } from '../reducers'
import { AutomationRulesState } from './automationRules/automationRulesReducer'
import { BlogState, selectors as blogSelectors } from './blog/blogReducer'
import { BlogPostState } from './blogPost/blogPostReducer'
import { CustomLoginState } from './custom-login/customLoginReducer'
import { FilesState } from './files/filesReducer'

export type Entities = Record<string, BaseEntityInterface | OldEntityInterface>

export interface PresentPage {
  id: number
  entities: Entities
  globalSettings: GlobalSettingsInterface
  parentGlobalSettings?: GlobalSettingsInterface
  seo?: SeoInterface
  type: PageTypeEnum
  locale: ValueOf<typeof localeValues>
  isTemplate: boolean
  doubleOptIn: boolean
  lastPopupNumber: number
  lastFormNumber: number
}

interface PageState {
  present: PresentPage
}

interface UserData {
  email: string
  name: string
  id: string
  isAdmin: boolean
  dashboardLocale: ValueOf<typeof localeValues>
  impersonator: {
    id: number
    email: string
    roles: string[]
  } | null
  isFreemium: boolean
  timezone: string
}

export interface ManagementState {
  editingEntityId: string | null
  faqActiveAnswerIndex: Record<string, number>
  customLoginMode: string
  user: UserData
  activePopupId: string | null
  sideMenuOpened: boolean
  dropDownMenuOpened: boolean
  instantUploadEnabled: boolean
  isCodeDialogOpened: false
  isCodeDialogPortalAllowed: false
  hasFacebookConversionPixel?: boolean
}

export interface PaymentState {
  offer: OfferInterface
  product: ProductInterface | null
  paymentMethods: PaymentMethodEnum[]
  offerBump: OfferInterface<OneShotPlan>
}

export interface RootState {
  readonly page: PageState
  readonly management: ManagementState
  readonly blog: BlogState
  readonly blogPost: BlogPostState
  readonly customLogin: CustomLoginState
  readonly files: FilesState
  readonly payment: PaymentState
  readonly automationRules: AutomationRulesState
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export function useManagement<T>(
  selector: (emailState: ManagementState) => T,
): T {
  return useTypedSelector(state => selector(state.management), shallowEqual)
}

export function usePage<T>(selector: (pageState: PresentPage) => T): T {
  return useTypedSelector(state => selector(state.page.present), shallowEqual)
}

export function useBlog<T>(selector: (blogState: BlogState) => T): T {
  return useTypedSelector(state => selector(state.blog), shallowEqual)
}

export function useBlogPost<T>(selector: (blogState: BlogPostState) => T): T {
  return useTypedSelector(state => {
    return state.blogPost && selector(state.blogPost)
  }, shallowEqual)
}

export function useCustomLogin<T>(
  selector: (blogState: CustomLoginState) => T,
): T {
  return useTypedSelector(
    state => state.customLogin && selector(state.customLogin),
    shallowEqual,
  )
}

export function usePayment<T>(selector: (paymentState: PaymentState) => T): T {
  return useTypedSelector(state => selector(state.payment), shallowEqual)
}

export function useFiles<T>(selector: (filesState: FilesState) => T): T {
  return useTypedSelector(state => selector(state.files), shallowEqual)
}

export function useAutomationRules<T>(
  selector: (automationRulesState: AutomationRulesState) => T,
): T {
  return useTypedSelector(
    state => selector(state.automationRules),
    shallowEqual,
  )
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const enhancer = applyMiddleware(reduxThunk, removeLostRulesMiddleware)

// interface AppStore extends Store {
//   asyncReducers: Record<string, Reducer>
//   injectReducer: (key: string, asyncReducer: any) => void
// }

export function createReducerManager(initialReducers: Record<string, Reducer>) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers }

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers)

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove: string[] = []

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state: Record<string, any>, action: AnyAction) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state }
        for (const key of keysToRemove) {
          delete state[key]
        }
        keysToRemove = []
      }
      // Delegate to the combined reducer
      return combinedReducer(state, action)
    },
    // Adds a new reducer with the specified key
    add: (key: string, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return
      }
      // Add the reducer to the reducer mapping
      reducers[key] = reducer
      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers)
    },
  }
}

export function configureStore() {
  const reducerManager = createReducerManager(staticReducers)

  // Create a store with the root reducer function being the one exposed by the manager.
  const store = createStore(
    // @ts-ignore
    reducerManager.reduce,
    {},
    composeEnhancers(enhancer),
  )

  // Optional: Put the reducer manager on the store so it is easily accessible
  // @ts-ignore
  store.reducerManager = reducerManager

  return store
}

export {
  managementSelectors,
  pageSelectors,
  blogSelectors,
  typedManagementSelectors,
  typedPageSelectors,
  typedPaymentSelectors,
}
