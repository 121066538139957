import { findLostEntitiesIds } from 'tools/utils/entityUtils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pageTypes } from 'common/constants/pageTypes'
import structureTypes from 'common/constants/structureTypes'
import { LocaleEnum } from 'common/enums/LocaleEnum'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { useActions } from 'common/hooks/useActions'
import { EntitiesStructure } from 'common/types/Page'
import { BaseEntityWithChildIdsInterface } from 'common/types/entities/EntityInterface'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import {
  getParentEntityTreeWithFixedParentId,
  getEntitiesByType,
  isWithChildIds,
  getEntityExistingChildIds,
  isEntityWithChildIds,
} from 'common/utils/entityUtils'
import { useIsImpersonatorAndSuperAdmin } from 'client/hooks/useIsImpersonatorAndSuperAdmin'
import { usePage } from 'client/store'
import { fixEntities } from 'client/store/page/pageActions'
import {
  checkIsEntityMigrated,
  getPageType,
  getReadableRootEntity,
} from 'client/store/page/pageSelectors'
import LanguageSwitcherUi from './ui/LanguageSwitcherUi'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [visible, setVisible] = useState(false)
  const isImpersonatorAndSuperAdmin = useIsImpersonatorAndSuperAdmin()
  const fixEntitiesAction = useActions(fixEntities)
  const entities = usePage(page => page.entities)
  const pageType = usePage(getPageType)
  const isMigratedInlinePage = usePage(page =>
    checkIsEntityMigrated(
      page,
      [structureTypes.INLINE, EntityTypeEnum.InlineBody],
      EntityTypeEnum.InlineBody,
    ),
  )
  const rootEntity = usePage(page =>
    getReadableRootEntity({
      ...page,
      type: isMigratedInlinePage ? PageTypeEnum.InlineBody : page.type,
    }),
  )

  useEffect(() => {
    if (isImpersonatorAndSuperAdmin) {
      setVisible(true)
    }
  }, [isImpersonatorAndSuperAdmin])

  if (!visible) {
    return <LanguageSwitcherUi />
  }

  function addParentId() {
    if (!rootEntity) {
      console.log('Root entity not found')
      return
    }

    const rootEntityTree = getParentEntityTreeWithFixedParentId(
      entities,
      rootEntity as OldEntityWithChildIds | BaseEntityWithChildIdsInterface,
    )
    const popups = getEntitiesByType(entities, structureTypes.POPUP)
    const popupsWithEntities = {} as EntitiesStructure
    Object.values(popups).forEach(cur => {
      if (isWithChildIds(cur)) {
        Object.assign(
          popupsWithEntities,
          getParentEntityTreeWithFixedParentId(entities, cur),
        )
      }
    })

    const fixedStructure = {
      [rootEntity.id]: rootEntity,
      ...rootEntityTree,
      ...popupsWithEntities,
      ...popups,
    }

    fixEntitiesAction(fixedStructure)
  }

  function removeLostChildren() {
    const filteredEntities = {} as EntitiesStructure

    Object.values(entities).forEach(entity => {
      if (isEntityWithChildIds(entity)) {
        filteredEntities[entity.id] = {
          ...entity,
          childIds: getEntityExistingChildIds(entities, entity),
        }
      } else {
        filteredEntities[entity.id] = entity
      }
    })

    const lostEntitiesIds = findLostEntitiesIds(
      filteredEntities,
      isMigratedInlinePage ? pageTypes.inlineBody : pageType,
    )

    lostEntitiesIds.forEach(entityId => {
      delete entities[entityId]
    })

    fixEntitiesAction(filteredEntities)
  }

  return (
    <LanguageSwitcherUi>
      <select
        onChange={async e => {
          await i18n.changeLanguage(e.target.value)
        }}
        value={i18n.language}
      >
        {Object.values(LocaleEnum).map(locale => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </select>
      &nbsp;
      <button onClick={addParentId}>Fix parents</button>
      &nbsp;
      <button onClick={removeLostChildren}>Remove lost children</button>
    </LanguageSwitcherUi>
  )
}

export default LanguageSwitcher
