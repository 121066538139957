import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogCategoryDescriptionInterface } from 'common/types/entities/BlogCategoryDescriptionInterface'
import { BlogCategoryTitleInterface } from 'common/types/entities/BlogCategoryTitleInterface'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import { ButtonInterface } from 'common/types/entities/ButtonInterface'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { FieldInterface } from 'common/types/entities/FieldInterface'
import { LatestBlogPostsCarouselInterface } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import { TextAreaInterface } from 'common/types/entities/TextAreaInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import Text from '../blog/entities/Text'
import BlogBulletList from '../blog/entities/blog-bullet-list'
import Attachments, {
  AttachmentsInterface,
} from '../contact-us/entities/Attachments'
import Button from '../contact-us/entities/Button'
import ContactUs from '../contact-us/entities/ContactUs'
import ContactUsField from '../contact-us/entities/ContactUsField'
import Recaptcha from '../contact-us/entities/Recaptcha'
import TextArea from '../contact-us/entities/TextArea'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import BlogCategoryDescription from './entities/BlogCategoryDescription'
import BlogCategoryTitle from './entities/BlogCategoryTitle'
import BlogLatestPosts from './entities/BlogLatestPosts'
import BlogPostListing from './entities/BlogPostListing'
import LatestBlogPostsCarousel from './entities/LatestBlogPostsCarousel'

export default function createEntityElementForBlogPage(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.BlogPostListing:
      return (
        <BlogPostListing
          entity={entity as BlogPostListingInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.LatestBlogPosts:
      return (
        <BlogLatestPosts
          entity={entity as LatestBlogPostsInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogCategoryTitle:
      return (
        <BlogCategoryTitle
          entity={entity as BlogCategoryTitleInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.BlogCategoryDescription:
      return (
        <BlogCategoryDescription
          entity={entity as BlogCategoryDescriptionInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Text:
      return <Text entity={entity as TextInterface} {...additionalProps} />
    case EntityTypeEnum.BulletList:
      return (
        <BlogBulletList
          entity={entity as BulletListInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.ContactUs:
      return (
        <ContactUs entity={entity as ContactUsInterface} {...additionalProps} />
      )
    case EntityTypeEnum.TextArea:
      return (
        <TextArea entity={entity as TextAreaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.ContactUsField:
    case EntityTypeEnum.Field:
      return (
        <ContactUsField
          entity={entity as FieldInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return (
        <Recaptcha entity={entity as RecaptchaInterface} {...additionalProps} />
      )
    case EntityTypeEnum.Button:
      return <Button entity={entity as ButtonInterface} {...additionalProps} />
    case EntityTypeEnum.Attachments:
      return (
        <Attachments
          entity={entity as AttachmentsInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.LatestBlogPostsCarousel:
      return (
        <LatestBlogPostsCarousel
          entity={entity as LatestBlogPostsCarouselInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
