import {
  migrateMargin,
  migrateMobileMargin,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createLanguageSwitcher } from 'client/components/entities/LanguageSwitcher/NewLanguageSwitcher'

export function migrateLanguageSwitcher(entity: OldEntityInterface) {
  const languageSwitcher = createLanguageSwitcher(
    entity.parentId,
    entity.masterBlockId,
  )

  if (entity.childIds && entity.childIds.length !== 0) {
    languageSwitcher.childIds = entity.childIds
  }

  languageSwitcher.id = entity.id

  languageSwitcher.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    languageSwitcher.appearance.desktop = entity.options.appearance.desktop
    languageSwitcher.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(languageSwitcher, entity)
  migrateMobileMargin(languageSwitcher, entity)

  //redirectPath
  if (entity.options.redirectPath) {
    languageSwitcher.redirectPath = entity.options.redirectPath
  }

  //excludedLanguages
  if (entity.options.excludedLanguages) {
    languageSwitcher.excludedLanguages = entity.options.excludedLanguages
  }

  //font
  if (entity.styles.fontFamily) {
    languageSwitcher.fontFamily = entity.styles.fontFamily
  }
  if (entity.styles.fontWeight) {
    languageSwitcher.fontWeight = entity.styles.fontWeight
  }
  if (entity.styles.fontStyle) {
    languageSwitcher.fontStyle = entity.styles.fontStyle
  }

  //mobileText
  if (entity.mobileStyles && entity.mobileStyles.fontFamily) {
    languageSwitcher.mobileFontFamily = entity.mobileStyles.fontFamily
  }
  if (entity.mobileStyles && entity.mobileStyles.fontWeight) {
    languageSwitcher.mobileFontWeight = entity.mobileStyles.fontWeight
  }
  if (entity.mobileStyles && entity.mobileStyles.fontStyle) {
    languageSwitcher.mobileFontStyle = entity.mobileStyles.fontStyle
  }

  //color
  if (entity.styles.color) {
    languageSwitcher.color = entity.styles.color
  }

  if (entity.mobileStyles && entity.mobileStyles.color) {
    languageSwitcher.mobileColor = entity.mobileStyles.color
  }

  //fontSize
  if (entity.styles.fontSize && !isNaN(parseInt(entity.styles.fontSize))) {
    languageSwitcher.fontSize = parseInt(entity.styles.fontSize)
  }
  if (
    entity.mobileStyles.fontSize &&
    !isNaN(parseInt(entity.mobileStyles.fontSize))
  ) {
    languageSwitcher.mobileFontSize = parseInt(entity.mobileStyles.fontSize)
  }

  //lineHeight
  if (entity.styles.lineHeight && !isNaN(parseInt(entity.styles.lineHeight))) {
    languageSwitcher.lineHeight = parseInt(entity.styles.lineHeight)
  }
  if (
    entity.mobileStyles.lineHeight &&
    !isNaN(parseInt(entity.mobileStyles.lineHeight))
  ) {
    languageSwitcher.mobileLineHeight = parseInt(entity.mobileStyles.lineHeight)
  }

  return languageSwitcher
}
