import {
  migrateMargin,
  migrateMobileMargin,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createAgreement } from 'client/components/core/Sidebar/components/Settings/entities/NewAgreementSettings/utils/create-agreement'

export function migrateAgreement(entity: OldEntityInterface) {
  const agreement = createAgreement(entity.parentId, entity.masterBlockId)

  agreement.id = entity.id
  agreement.htmlAttrId = entity.options.attrId

  if (entity.options.appearance) {
    agreement.appearance.desktop = entity.options.appearance.desktop
    agreement.appearance.mobile = entity.options.appearance.mobile
  }

  // margins
  migrateMargin(agreement, entity)
  migrateMobileMargin(agreement, entity)

  return agreement
}
