import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'

const defaultRow = {
  [EntityTypeEnum.Row]: {
    children: [
      {
        [EntityTypeEnum.Column]: {},
      },
    ],
  },
}

const defaultScheme = {
  [structureTypes.BODY]: {
    children: [
      {
        [EntityTypeEnum.Section]: {
          children: [defaultRow],
        },
      },
    ],
  },
  [structureTypes.BLOG_POST_BODY]: {
    children: [
      {
        [EntityTypeEnum.Section]: {
          children: [defaultRow],
        },
      },
    ],
  },
  [structureTypes.BLOG_POST_LAYOUT_BODY]: {
    children: [
      {
        [EntityTypeEnum.Section]: {
          children: [defaultRow],
        },
      },
    ],
  },
  [structureTypes.BLOG_PAGE_BODY]: {
    children: [
      {
        [EntityTypeEnum.Section]: {
          children: [defaultRow],
        },
      },
    ],
  },
  [EntityTypeEnum.Section]: {
    children: [defaultRow],
  },
  [structureTypes.SECTION]: {
    children: [defaultRow],
  },
  [EntityTypeEnum.Row]: {
    children: [
      {
        [EntityTypeEnum.Column]: {},
      },
    ],
  },
  [EntityTypeEnum.Column]: {
    children: [],
  },
  [structureTypes.ROW]: {
    children: [
      {
        [structureTypes.COLUMN_12]: {},
      },
    ],
  },
  [structureTypes.COLUMN]: {
    children: [],
  },
  [structureTypes.POPUP]: {
    children: [defaultRow],
  },
  [structureTypes.REMOTE_POPUP]: {
    children: [defaultRow],
  },
  [structureTypes.INLINE]: {
    children: [defaultRow],
  },
  [EntityTypeEnum.InlineBody]: {
    children: [defaultRow],
  },
  [structureTypes.CONTENT_BOX]: {
    children: [],
  },
  [EntityTypeEnum.ContentBox]: {
    children: [],
  },
  [EntityTypeEnum.FaqItem]: {
    children: [],
  },
  [EntityTypeEnum.CarouselItem]: {
    children: [],
  },
  [EntityTypeEnum.ContactUs]: {
    children: [defaultRow],
  },
  [structureTypes.IMAGE]: structureTypes.IMAGE,
  [structureTypes.FORM_INPUT]: structureTypes.FORM_INPUT,
  [structureTypes.BUTTON]: structureTypes.BUTTON,
  [structureTypes.TEXT]: structureTypes.TEXT,
  [structureTypes.BULLET_LIST]: structureTypes.BULLET_LIST,
  [structureTypes.SURVEY]: {
    children: [defaultRow],
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN]: {
    children: [defaultRow],
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT]: {
    children: [defaultRow],
  },
}

export default defaultScheme
