import { MenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { AlignSelfType } from 'common/types/styleTypes'

export interface AlignSelfInterface {
  alignSelf: AlignSelfType
  mobileAlignSelf?: AlignSelfType
}

export interface SpacingInterface {
  spacing: number
  mobileSpacing?: number
}

export interface ColorInterface {
  color?: string
  mobileColor?: string
}

export interface ActiveColorInterface {
  activeColor: string
  mobileActiveColor?: string
}

export interface MenuItemsInterface {
  menuItems: MenuItemInterface[]
}

export interface MenuBurgerInterface {
  burger: boolean
  burgerIconColor: string
}

export interface ItemUnderlineColorInterface {
  itemUnderlineColor?: string
  mobileItemUnderlineColor?: string
}

export function migrateAlignSelf(
  newEntity: AlignSelfInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.styles.justifyContent) {
    newEntity.alignSelf = originalEntity.styles.justifyContent
  }

  //mobileAlignSelf
  if (originalEntity.mobileStyles.justifyContent) {
    newEntity.mobileAlignSelf = originalEntity.mobileStyles.justifyContent
  }
}

export function migrateSpacing(
  newEntity: SpacingInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.options.spacing) {
    newEntity.spacing = originalEntity.options.spacing
  }

  //mobileAlignSelf
  if (originalEntity.mobileOptions && originalEntity.mobileOptions.spacing) {
    newEntity.mobileSpacing = originalEntity.mobileOptions.spacing
  }
}

export function migrateColor(
  newEntity: ColorInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.styles.color) {
    newEntity.color = originalEntity.styles.color
  }

  //mobileAlignSelf
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.color) {
    newEntity.mobileColor = originalEntity.mobileStyles.color
  }
}

export function migrateActiveColor(
  newEntity: ActiveColorInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.options.activeColor) {
    newEntity.activeColor = originalEntity.options.activeColor
  }

  //mobileAlignSelf
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.activeColor
  ) {
    newEntity.mobileActiveColor = originalEntity.mobileOptions.activeColor
  }
}

export function migrateItemUnderlineColor(
  newEntity: ItemUnderlineColorInterface,
  originalEntity: OldEntityInterface,
) {
  //alignSelf
  if (originalEntity.options.itemUnderlineColor) {
    newEntity.itemUnderlineColor = originalEntity.options.itemUnderlineColor
  }

  //mobileAlignSelf
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.itemUnderlineColor
  ) {
    newEntity.mobileItemUnderlineColor =
      originalEntity.mobileOptions.itemUnderlineColor
  }
}

export function migrateMenuItems(
  newEntity: MenuItemsInterface,
  originalEntity: any,
) {
  //alignSelf
  if (originalEntity.options.menuItems) {
    newEntity.menuItems = originalEntity.options.menuItems
  }
}

export function migrateMenuBurgerProperty(
  newEntity: MenuBurgerInterface,
  originalEntity: any,
) {
  //alignSelf
  if (originalEntity.options.burger) {
    newEntity.burger = originalEntity.options.burger
  }
  if (originalEntity.options.burgerColor) {
    newEntity.burgerIconColor = originalEntity.options.burgerColor
  }
}
