import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import { createText } from 'client/components/entities/Text/Text'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

export const unsupportedBlockTypes = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
]

export function migrateText(entity: OldTextEntityInterface): TextInterface {
  const text = createText(entity.parentId, entity.masterBlockId)

  text.id = entity.id
  if (entity.isMasterBlockRoot) {
    text.isMasterBlockRoot = true
  }
  let rawContentState = entity.options.rawContentState
  unsupportedBlockTypes.forEach(type => {
    if (rawContentState.includes(`"type":"${type}"`)) {
      console.log(`includes ${type}`)
      rawContentState = rawContentState.replace(
        `"type":"${type}"`,
        `"type":"unstyled"`,
      )
    }
  })

  text.rawContentState = rawContentState

  migrateMargin(text, entity)

  if (entity.styles.padding) {
    text.padding = migratePadding(entity.styles.padding)
  }

  if (entity.options.appearance) {
    text.appearance.desktop = entity.options.appearance.desktop
    text.appearance.mobile = entity.options.appearance.mobile
  }

  text.htmlAttrId = entity.options.attrId
  text.textAlign = entity.styles.textAlign
  text.fontFamily = entity.styles.fontFamily
  text.fontWeight = entity.styles.fontWeight
  text.fontStyle = entity.styles.fontStyle

  if (entity.styles.backgroundColor) {
    text.backgroundColor = entity.styles.backgroundColor
  }

  if (
    entity.styles.letterSpacing &&
    !isNaN(parseInt(entity.styles.letterSpacing))
  ) {
    text.letterSpacing = parseInt(entity.styles.letterSpacing)
  }
  if (entity.styles.fontSize && !isNaN(parseInt(entity.styles.fontSize))) {
    text.fontSize = parseInt(entity.styles.fontSize)
  }
  if (entity.styles.lineHeight && !isNaN(parseInt(entity.styles.lineHeight))) {
    text.lineHeight = parseInt(entity.styles.lineHeight)
  }

  // MOBILE SETTINGS
  if (
    entity.mobileStyles.letterSpacing &&
    !isNaN(parseInt(entity.mobileStyles.letterSpacing))
  ) {
    text.mobileLetterSpacing = parseInt(entity.mobileStyles.letterSpacing)
  }
  if (
    entity.mobileStyles.fontSize &&
    !isNaN(parseInt(entity.mobileStyles.fontSize))
  ) {
    text.mobileFontSize = parseInt(entity.mobileStyles.fontSize)
  }
  if (
    entity.mobileStyles.lineHeight &&
    !isNaN(parseInt(entity.mobileStyles.lineHeight))
  ) {
    text.mobileLineHeight = parseInt(entity.mobileStyles.lineHeight)
  }

  if (entity.mobileStyles.padding) {
    text.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateMobileMargin(text, entity)

  text.mobileFontFamily = entity.mobileStyles.fontFamily
  text.mobileFontWeight = entity.mobileStyles.fontWeight
  text.mobileFontStyle = entity.mobileStyles.fontStyle

  if (entity.mobileStyles.backgroundColor) {
    text.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }

  if (entity.mobileStyles.textAlign) {
    text.mobileTextAlign = entity.mobileStyles.textAlign
  }

  return text
}
