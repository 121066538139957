import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createHorizontalLine } from 'client/components/entities/NewHorizontalLine'
import {
  migrateMargin,
  migrateMobileMargin,
  migrateFullBorderType,
} from '../utils/migrateUtils'

export function migrateHorizontalLine(
  entity: OldEntityInterface,
): HorizontalLineInterface {
  const horizontalLine = createHorizontalLine(
    entity.parentId,
    entity.masterBlockId,
  )

  //id and masterBlock
  horizontalLine.id = entity.id
  if (entity.isMasterBlockRoot) {
    horizontalLine.isMasterBlockRoot = true
  }
  //appearance
  if (entity.options.appearance) {
    horizontalLine.appearance.desktop = entity.options.appearance.desktop
    horizontalLine.appearance.mobile = entity.options.appearance.mobile
  }
  //attrId
  horizontalLine.htmlAttrId = entity.options.attrId

  //margins
  migrateMargin(horizontalLine, entity)
  migrateMobileMargin(horizontalLine, entity)
  migrateFullBorderType(horizontalLine, entity)

  return horizontalLine
}
