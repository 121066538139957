import {
  ButtonInterface,
  SalesRedirectionButtonInterface,
  UpsellButtonInterface,
} from 'common/types/entities/ButtonInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { WebinarCallToActionInterface } from 'common/types/entities/WebinarCallToActionInterface'

export type ButtonUnionType =
  | ButtonInterface
  | UpsellButtonInterface
  | WebinarCallToActionInterface
  | SalesRedirectionButtonInterface
  | PaymentButtonInterface

export function migrateButtonText(
  newEntity: ButtonUnionType,
  originalEntity: OldEntityInterface,
) {
  //text
  if (originalEntity.styles.fontFamily) {
    newEntity.textFontFamily = originalEntity.styles.fontFamily
    newEntity.textFontWeight = originalEntity.styles.fontWeight
    newEntity.textFontStyle = originalEntity.styles.fontStyle
  }

  //mobileText
  if (originalEntity.mobileStyles && originalEntity.mobileStyles.fontFamily) {
    newEntity.mobileTextFontFamily = originalEntity.mobileStyles.fontFamily
    newEntity.mobileTextFontWeight = originalEntity.mobileStyles.fontWeight
    newEntity.mobileTextFontStyle = originalEntity.mobileStyles.fontStyle
  }
  //fontSettings subText

  if (originalEntity.options.subTextFontFamily) {
    newEntity.subTextFontFamily = originalEntity.options.subTextFontFamily
    newEntity.subTextFontWeight = originalEntity.options.subTextFontWeight
    newEntity.subTextFontStyle = originalEntity.options.subTextFontStyle
  }
  //fontSettings subText
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.subTextFontFamily
  ) {
    newEntity.mobileSubTextFontFamily =
      originalEntity.mobileOptions.subTextFontFamily
    newEntity.mobileSubTextFontWeight =
      originalEntity.mobileOptions.subTextFontWeight
    newEntity.mobileSubTextFontStyle =
      originalEntity.mobileOptions.subTextFontStyle
  }
}

export function migrateButtonIcons(
  newEntity: ButtonUnionType,
  originalEntity: OldEntityInterface,
) {
  //icons
  if (originalEntity.options.iconClassNameBefore) {
    newEntity.iconClassNameBefore = originalEntity.options.iconClassNameBefore
  }
  if (originalEntity.options.iconClassNameAfter) {
    newEntity.iconClassNameAfter = originalEntity.options.iconClassNameAfter
  }
}

export function migrateButtonTextColor(
  newEntity: ButtonUnionType,
  originalEntity: OldEntityInterface,
) {
  //textColor
  if (originalEntity.styles.color) {
    newEntity.textColor = originalEntity.styles.color
  }
  //subTextColor
  if (originalEntity.options.subTextColor) {
    newEntity.subTextColor = originalEntity.options.subTextColor
  }
  //mobileTextColor
  if (originalEntity.mobileStyles.color) {
    newEntity.mobileTextColor = originalEntity.mobileStyles.color
  }
  //mobileSubTextColor
  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.subTextColor
  ) {
    newEntity.mobileSubTextColor = originalEntity.mobileOptions.subTextColor
  }
}

export function migrateButtonWidth(
  newEntity: ButtonUnionType,
  originalEntity: OldEntityInterface,
) {
  //width
  if (originalEntity.styles.width) {
    newEntity.width = originalEntity.styles.width
  }
  //mobileWidth
  if (originalEntity.mobileStyles.width) {
    newEntity.mobileWidth = originalEntity.mobileStyles.width
  }
}

export function migrateButtonFontSize(
  newEntity: ButtonUnionType,
  originalEntity: OldEntityInterface,
) {
  //fontSize
  if (
    originalEntity.styles.fontSize &&
    !isNaN(parseInt(originalEntity.styles.fontSize))
  ) {
    newEntity.textFontSize = parseInt(originalEntity.styles.fontSize)
  }
  if (
    originalEntity.mobileStyles.fontSize &&
    !isNaN(parseInt(originalEntity.mobileStyles.fontSize))
  ) {
    newEntity.mobileTextFontSize = parseInt(
      originalEntity.mobileStyles.fontSize,
    )
  }

  //subTextFontSize
  if (
    originalEntity.options.subTextFontSize &&
    !isNaN(parseInt(originalEntity.options.subTextFontSize))
  ) {
    newEntity.subTextFontSize = parseInt(originalEntity.options.subTextFontSize)
  }

  if (
    originalEntity.mobileOptions &&
    originalEntity.mobileOptions.subTextFontSize &&
    !isNaN(parseInt(originalEntity.mobileOptions.subTextFontSize))
  ) {
    newEntity.mobileSubTextFontSize = parseInt(
      originalEntity.mobileOptions.subTextFontSize,
    )
  }
}
