import {
  migrateFontSettings,
  migrateFontSize,
  migrateLineHeight,
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from 'tools/commands/migrate/utils/migrateUtils'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createBulletList } from 'client/components/core/Sidebar/components/Settings/entities/NewBulletListSettings/utils/create-bullet-list'

export function migrateBulletList(entity: OldEntityInterface) {
  const bulletList = createBulletList(entity.parentId, entity.masterBlockId)

  if (entity.options.rawContentState) {
    bulletList.rawContentState = entity.options.rawContentState
  }

  if (entity.childIds && entity.childIds.length !== 0) {
    bulletList.childIds = entity.childIds
  }

  bulletList.id = entity.id
  bulletList.htmlAttrId = entity.options.attrId
  bulletList.textAlign = entity.styles.textAlign

  if (entity.options.appearance) {
    bulletList.appearance.desktop = entity.options.appearance.desktop
    bulletList.appearance.mobile = entity.options.appearance.mobile
  }

  migrateMargin(bulletList, entity)
  migrateMobileMargin(bulletList, entity)
  migrateFontSettings(bulletList, entity)
  migrateFontSize(bulletList, entity)
  migrateLineHeight(bulletList, entity)

  if (entity.options.delay && !isNaN(parseInt(entity.options.delay))) {
    bulletList.delay = parseInt(entity.options.delay)
  }

  if (
    entity.options.verticalSpacing &&
    !isNaN(parseInt(entity.options.verticalSpacing))
  ) {
    bulletList.verticalSpacing = parseInt(entity.options.verticalSpacing)
  }

  if (
    entity.mobileOptions.verticalSpacing &&
    !isNaN(parseInt(entity.mobileOptions.verticalSpacing))
  ) {
    bulletList.mobileVerticalSpacing = parseInt(
      entity.mobileOptions.verticalSpacing,
    )
  }

  if (entity.styles.backgroundColor) {
    bulletList.backgroundColor = entity.styles.backgroundColor
  }

  if (entity.mobileStyles.backgroundColor) {
    bulletList.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }

  if (entity.styles.padding) {
    bulletList.padding = migratePadding(entity.styles.padding)
  }

  if (entity.mobileStyles.padding) {
    bulletList.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  //color
  if (entity.styles.color) {
    bulletList.color = entity.styles.color
  }

  if (entity.mobileStyles && entity.mobileStyles.color) {
    bulletList.mobileColor = entity.mobileStyles.color
  }

  if (entity.mobileStyles.textAlign) {
    bulletList.mobileTextAlign = entity.mobileStyles.textAlign
  }

  bulletList.listItemIconColor = entity.options.listItemIconColor

  if (
    entity.options.listItemIconSize &&
    !isNaN(parseInt(entity.options.listItemIconSize))
  ) {
    bulletList.listItemIconSize = parseInt(entity.options.listItemIconSize)
  }

  bulletList.listItemClassName = entity.options.listItemClassName

  if (entity.mobileOptions.listItemIconColor) {
    bulletList.mobileListItemIconColor = entity.mobileOptions.listItemIconColor
  }

  return bulletList
}
