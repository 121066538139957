import React from 'react'
import { useTranslation } from 'react-i18next'
import { AgreementInterface } from 'common/types/entities/AgreementInterface'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { PaymentSettingsContainerUI } from './ui/payment-settings-container'

const getPaymentSettingsLink = (linkText: string) => `
  <a
    href="${process.env.SYSTEME_IO_HOST}/dashboard/profile/payment-settings"
    target="_blank"
  >
    ${linkText}
  </a>
`

export interface AgreementSettingsProps {
  entity: AgreementInterface
}

const NewAgreementSettings = ({ entity }: AgreementSettingsProps) => {
  const { t } = useTranslation()
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()

  return (
    <>
      <PaymentSettingsContainerUI
        dangerouslySetInnerHTML={{
          __html: t('entity_settings.agreement.read_only.text', {
            link: getPaymentSettingsLink(
              t('entity_settings.agreement.read_only.link_text'),
            ),
          }),
        }}
      />

      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />

      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default NewAgreementSettings
