import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'

export const oldTypeToNew = (
  oldEntity: OldEntityInterface,
): CountdownTypesEnum => {
  switch (oldEntity.options.type) {
    case 'date':
      return CountdownTypesEnum.fixed
    case 'minute':
      return CountdownTypesEnum.delay
    case 'daily':
      return CountdownTypesEnum.daily
    case 'deadline':
      return CountdownTypesEnum.deadline
    default:
      return CountdownTypesEnum.fixed
  }
}
