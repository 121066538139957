import { v4 as uuid } from 'uuid'
import { actions as surveyActions } from 'common/components/entities/Survey'
import { redirectionTypes } from 'common/constants/buttonTypes'
import {
  countdownActions,
  countdownTypes,
} from 'common/constants/countdownTypes'
import {
  backgroundPositionKeys,
  borderTypeKeys,
  buttonActionsTypes,
  defaultTextFontSize,
  widthNames,
} from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import videoTypes from 'common/constants/videoTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'

export const entityBorderColors = {
  blue: '#149EFC',
  green: '#56C098',
  orange: '#F78828',
  gray: '#5A6E73',
}

export const translatableOptionKeys = [
  'text',
  'subText',
  'title',
  'subTitle',
  'errorText',
  'placeholder',
  'loadingText',
  'headerTitle',
  'loginHeadline',
  'registrationHeadline',
  'resettingHeadline',
  'resettingButtonText',
  'loginButtonText',
  'registrationButtonText',
  'emailLabel',
  'emailPlaceholder',
  'passwordLabel',
  'passwordPlaceholder',
  'passwordConfirmLabel',
  'passwordConfirmPlaceholder',
  'buttonText',
  'footerText',
  'buttonSubText',
  'subHeadline',
  'headline',
]

export const draftOptions = ['rawContentState']

export const appearanceSettings = {
  appearance: {
    desktop: true,
    mobile: true,
  },
}
const currentDate = new Date()
const in10DaysDate = new Date().setDate(currentDate.getDate() + 10)

export const defaultStyles = {
  [structureTypes.SURVEY]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    padding: '15px 100px 15px 100px',
  },
  [structureTypes.COUNTDOWN]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.BUTTON]: {
    fontSize: '20px',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    borderBottomLeftRadius: '3px',
    color: 'rgba(255, 255, 255, 1)',
    background: 'rgba(1, 116, 199, 1)',
    padding: '15px 90px 15px 90px',
    marginTop: '25px',
    alignSelf: 'center',
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: 'rgba(1,77,130,1)',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'auto',
  },
  [structureTypes.SECTION]: {
    padding: '40px 40px 40px 40px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.ROW]: {
    columnGap: '20px',
    padding: '10px 10px 10px 10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.IMAGE]: {
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.FORM_INPUT]: {
    marginTop: '25px',
    fontSize: defaultTextFontSize,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(176, 186, 202, 1)',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.TEXT]: {
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '20px',
  },
  [structureTypes.BULLET_LIST]: {
    textAlign: 'left',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '30px',
    marginRight: '0px',
    fontSize: '20px',
    padding: '5px 5px 5px 5px',
    lineHeight: '34px',
  },
  [structureTypes.INLINE]: {
    width: '400px',
  },
  [EntityTypeEnum.InlineBody]: {
    width: '400px',
  },
  [structureTypes.POPUP]: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: 'rgba(216, 211, 211, 1)',
    padding: '10px',
  },
  [structureTypes.CONTENT_BOX]: {
    minHeight: '100px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(85, 121, 195, 1)',
    padding: '10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.WEBINAR_SESSION_DATE_TIME]: {
    padding: '10px',
    fontSize: '18px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.WEBINAR_SESSION_CALL_TO_ACTION]: {
    fontSize: '20px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(1, 116, 199, 1)',
    padding: '9px 25px 9px 25px',
    marginTop: '25px',
    alignSelf: 'center',
    borderStyle: 'none',
    borderWidth: '1px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'auto',
  },
  [structureTypes.PAYMENT_BUTTON]: {
    borderStyle: 'none',
    borderWidth: '1px',
    fontSize: '20px',
    background: 'rgba(1, 116, 199, 1)',
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '0px',
    marginRight: '0px',
    alignSelf: 'center',
  },
  [structureTypes.TWITTER_TWEET_BUTTON]: {
    alignSelf: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'auto',
  },
  [structureTypes.ORDER_BUMP]: {
    padding: '8px 8px 8px 8px',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderStyle: 'dashed',
    borderWidth: '3px',
    backgroundColor: 'rgba(251, 248, 230, 1)',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.EXPLICIT_CONSENT]: {
    padding: '10px 10px 10px 10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.PHYSICAL_PRODUCT]: {
    padding: '10px 10px 10px 10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.SALES_REDIRECTION_BUTTON]: {
    fontSize: '20px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(1, 116, 199, 1)',
    alignSelf: 'center',
    padding: '9px 25px 9px 25px',
    marginTop: '25px',
    borderStyle: 'none',
    borderWidth: '1px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'auto',
  },
  [structureTypes.COUPON]: {
    padding: '10px 10px 10px 10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.BLOG_POST_CONTENT_PLACEHOLDER]: {
    fontSize: defaultTextFontSize,
    color: 'rgba(0, 0, 0, 1)',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.RAW_HTML]: {
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.LANGUAGE_SWITCHER]: {
    fontSize: '16px',
    color: 'rgba(149, 164, 186, 1)',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM]: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'rgba(0, 0, 0, 0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    marginTop: '0',
    marginRight: '0',
    marginBottom: '0',
    marginLeft: '0',
  },
  [structureTypes.AUDIO]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
}

export const defaultMobileStyles = {
  [structureTypes.CUSTOMER_TYPE]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.AUDIO]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.COUNTDOWN]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.SECTION]: {
    padding: '5px 5px 5px 5px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.ROW]: {
    padding: '5px 5px 5px 5px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.INLINE]: {
    padding: '5px 5px 5px 5px',
  },
  [EntityTypeEnum.InlineBody]: {
    padding: '5px 5px 5px 5px',
  },
  [structureTypes.POPUP]: {
    padding: '5px 5px 5px 5px',
  },
  [structureTypes.BUTTON]: {
    fontSize: '20px',
    padding: '5px 5px 5px 5px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    alignSelf: 'center',
    boxShadow: 'none',
  },
  [structureTypes.WEBINAR_SESSION_CALL_TO_ACTION]: {
    padding: '5px 5px 5px 5px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    alignSelf: 'center',
  },
  [structureTypes.FORM_INPUT]: {
    marginTop: '10px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.PAYMENT_BUTTON]: {
    fontSize: '20px',
    padding: '5px 5px 5px 5px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
  },
  [structureTypes.CONTENT_BOX]: {
    padding: '10px 10px 10px 10px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.PHYSICAL_PRODUCT]: {
    padding: '5px 5px 5px 5px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.SALES_REDIRECTION_BUTTON]: {
    fontSize: '20px',
    padding: '5px 5px 5px 5px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    alignSelf: 'center',
    boxShadow: 'none',
  },
  [structureTypes.COUPON]: {
    padding: '5px 5px 5px 5px',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.RAW_HTML]: {
    marginTop: '0',
    marginRight: '0',
    marginBottom: '0',
    marginLeft: '0',
  },
  [structureTypes.IMAGE]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  [structureTypes.SURVEY]: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    padding: '15px 15px 15px 15px',
  },
  [structureTypes.BULLET_LIST]: {
    textAlign: 'left',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '10px',
    marginRight: '0px',
    fontSize: '18px',
    lineHeight: '28px',
  },
}

export const defaultOptions = {
  [structureTypes.SECTION]: {
    backgroundPosition: backgroundPositionKeys.noRepeat,
    width: widthNames.medium,
    ...appearanceSettings,
  },
  [structureTypes.TEXT]: {
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
      '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
      'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
      'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
    ...appearanceSettings,
  },
  [structureTypes.BULLET_LIST]: {
    listItemIconColor: 'rgba(20, 158, 252, 1)',
    listItemIconSize: '20px',
    listItemClassName: 'fas fa-check',
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
      '"text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],' +
      '"data":{}},{"key":"a8v7j","text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],' +
      '"entityRanges":[],"data":{}}, {"key":"9cjsa","text":"Lorem ipsum dolor sit amet","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],' +
      '"data":{}}]}',
    ...appearanceSettings,
  },
  [structureTypes.BUTTON]: {
    borderType: borderTypeKeys.bottomOnly,
    action: buttonActionsTypes.sendForm,
    redirectionType: redirectionTypes.nextStep,
    text: 'entities.button.default_text',
    subText: 'entities.button.default_sub_text',
    subTextFontSize: '14px',
    subTextColor: 'rgba(255,255,255,0.8)',
    iconClassNameBefore: '',
    iconClassNameAfter: '',
    ...appearanceSettings,
  },
  [structureTypes.PAYMENT_BUTTON]: {
    borderType: borderTypeKeys.fullBorder,
    text: 'entities.payment_button.default_text',
    loadingText: 'entities.payment_button.default_loading_text',
    subTextFontSize: '14px',
    subTextColor: 'rgba(255,255,255,0.8)',
  },
  [structureTypes.SALES_REDIRECTION_BUTTON]: {
    borderType: borderTypeKeys.fullBorder,
    text: 'entities.sales_redirection_button.default_text',
    loadingText: 'entities.payment_button.default_loading_text',
    subTextFontSize: '14px',
    subTextColor: 'rgba(255,255,255,0.8)',
  },
  [structureTypes.POPUP]: {
    title: null,
    openAutomatically: '1',
    delay: 1,
    openOnMobileAutomatically: '0',
    mobileDelay: 1,
  },
  [structureTypes.CONTENT_BOX]: {
    headerTitle: 'entities.content_box.default_header_title',
    headerVisibility: '1',
    headerStyles: {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(85, 121, 195, 1)',
      fontSize: '20',
      justifyContent: 'flex-start',
    },
    ...appearanceSettings,
  },
  [structureTypes.ROW]: {
    ...appearanceSettings,
  },
  [structureTypes.WEBINAR_SESSION_DATE_TIME]: {
    iconStyles: {
      fontSize: 20,
    },
    ...appearanceSettings,
  },
  [structureTypes.WEBINAR_SESSION_CALL_TO_ACTION]: {
    borderType: borderTypeKeys.fullBorder,
    delay: 0,
    text: 'entities.webinar_session_call_to_action.default_text',
    ...appearanceSettings,
  },
  [structureTypes.RAW_HTML]: {
    html: '<h2>systeme.io</h2>',
    ...appearanceSettings,
  },
  [structureTypes.EXPLICIT_CONSENT]: {
    rawContentState:
      '{"blocks":[{"key":"7gjsu","text":"entities.explicit_consent.default_text","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    errorText: 'settings_options.checkbox_message',
    textAlign: 'center',
    ...appearanceSettings,
  },
  [structureTypes.ORDER_BUMP]: {
    title: 'entities.order_bump.default_title',
    showArrow: '1',
    headerStyles: {
      fontSize: 24,
      lineHeight: '59px',
      fontWeight: 'bold',
      backgroundColor: 'rgba(254, 250, 169, 1)',
      color: 'rgba(68, 149, 49, 1)',
      justifyContent: 'center',
    },
    ...appearanceSettings,
  },
  [structureTypes.START_TIMER]: {
    timeStyles: {
      color: 'rgba(208, 2, 27, 1)',
      fontSize: 20,
    },
    labelStyles: {
      color: 'rgba(155, 155, 155, 1)',
      fontSize: 16,
    },
    ...appearanceSettings,
  },
  [structureTypes.COUNTDOWN]: {
    type: countdownTypes.date,
    action: countdownActions.nothing,
    timeStyles: {
      color: 'rgba(208, 2, 27, 1)',
      fontSize: 20,
    },
    labelStyles: {
      color: 'rgba(155, 155, 155, 1)',
      fontSize: 16,
    },
    dateOptions: {
      date: in10DaysDate,
    },
    dailyOptions: {
      hours: 23,
      minutes: 59,
      seconds: 59,
    },
    minuteOptions: {
      days: 0,
      hours: 1,
      minutes: 0,
      seconds: 0,
      createdTime: new Date().getTime(),
    },
    ...appearanceSettings,
  },
  [structureTypes.AUDIO]: {
    ...appearanceSettings,
  },
  [structureTypes.WEBINAR_SESSION_DATE_TIME]: {
    ...appearanceSettings,
  },
  [structureTypes.WEBINAR_SESSION_VIDEO]: {
    type: videoTypes.URL,
    autoPlay: true,
    controls: false,
    url: 'https://data.systeme.io/systeme-common/default.mp4',
    ...appearanceSettings,
  },
  [structureTypes.FORM_INPUT]: {
    slug: null,
    ...appearanceSettings,
  },
  [structureTypes.IMAGE]: {
    ...appearanceSettings,
  },
  [structureTypes.PAYMENT_METHOD]: {
    ...appearanceSettings,
  },
  [structureTypes.CUSTOMER_TYPE]: {
    ...appearanceSettings,
  },
  [structureTypes.OFFER_PRICE]: {
    ...appearanceSettings,
  },
  [structureTypes.AGREEMENT]: {
    ...appearanceSettings,
  },
  [structureTypes.PHYSICAL_PRODUCT]: {
    ...appearanceSettings,
    attributeStyles: {
      fontSize: '14px',
    },
    attributeOptionStyles: {
      fontSize: '14px',
    },
    showQuantity: true,
  },
  [structureTypes.COUPON]: {
    fieldStyles: {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      border: '1px solid #444',
      color: 'rgba(68, 68, 68, 1)',
      fontSize: '18px',
    },
    buttonStyles: {
      backgroundColor: 'rgba(68, 68, 68, 1)',
      color: 'rgba(255, 255, 255, 1)',
      fontSize: '18px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      border: '1px solid #444',
    },
    fieldOptions: {},
    buttonOptions: {},
    buttonText: 'entities.coupon.default_button_text',
    placeholder: 'entities.coupon.default_field_placeholder',
    ...appearanceSettings,
  },
  [structureTypes.LANGUAGE_SWITCHER]: {
    redirectPath: '',
    excludedLanguages: [],
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM]: {
    borderType: borderTypeKeys.none,
    headerUnderlineColor: 'rgba(0, 116, 199, 1)',
    buttonBackgroundColor: 'rgba(0, 116, 199, 1)',
    buttonTextColor: 'rgba(255, 255, 255, 1)',
    headerBackgroundColor: 'rgba(0, 0, 0, 0.8)',
    headerTextColor: 'rgba(255,255,255, 1)',
    contentPadding: '5px 5px 5px 5px',
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN]: {
    headline: 'entities.two_step_payment_form.step_opt_in.default_headline',
    subHeadline:
      'entities.two_step_payment_form.step_opt_in.default_sub_headline',
    buttonText:
      'entities.two_step_payment_form.step_opt_in.default_button_text',
    buttonSubText:
      'entities.two_step_payment_form.step_opt_in.default_button_sub_text',
    footerText:
      'entities.two_step_payment_form.step_opt_in.default_footer_text',
  },
  [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT]: {
    headline: 'entities.two_step_payment_form.step_payment.default_headline',
    subHeadline:
      'entities.two_step_payment_form.step_payment.default_sub_headline',
    buttonText:
      'entities.two_step_payment_form.step_payment.default_button_text',
    buttonSubText:
      'entities.two_step_payment_form.step_payment.default_button_sub_text',
    footerText:
      'entities.two_step_payment_form.step_payment.default_footer_text',
  },
  [structureTypes.SURVEY]: {
    answerBackgroundColor: 'rgba(240,244,248, 1)',
    answerOutlineColor: 'rgba(20,158,252,1)',
    answerColor: 'rgba(93 , 100, 110, 1)',
    questions: [
      {
        id: uuid(),
        title: 'Add Your Question Here',
        subTitle: 'Add a description to your question here',
        answers: [
          {
            id: uuid(),
            title: 'Answer 1',
          },
          {
            id: uuid(),
            title: 'Answer 2',
          },
          {
            id: uuid(),
            title: 'Answer 3',
          },
        ],
      },
    ],
    answerBorderRadius: {
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      borderBottomLeftRadius: 25,
    },
    questionTitleFontSize: '30px',
    questionSubTitleFontSize: '16px',
    answerFontSize: '18px',
    questionTitleColor: 'rgba(20, 45, 99, 1)',
    questionSubTitleColor: 'rgba(139, 146, 156, 1)',
    action: surveyActions.nothing,
  },
}

export const defaultMobileOptions = {
  [structureTypes.SURVEY]: {
    questionTitleFontSize: '26px',
    answerBorderRadius: {},
  },
  [structureTypes.CONTENT_BOX]: {
    headerStyles: {},
  },
  [structureTypes.START_TIMER]: {
    labelStyles: {
      fontSize: '14px',
      color: 'rgba(155, 155, 155, 1)',
    },
    timeStyles: {
      fontSize: '20px',
      color: 'rgba(208, 2, 27, 1)',
    },
  },
  [structureTypes.BUTTON]: {
    subTextFontSize: '14px',
  },
  [structureTypes.PAYMENT_BUTTON]: {
    subTextFontSize: '14px',
  },
  [structureTypes.SALES_REDIRECTION_BUTTON]: {
    subTextFontSize: '14px',
  },
  [structureTypes.COUNTDOWN]: {
    labelStyles: {
      fontSize: '14px',
      color: 'rgba(155, 155, 155, 1)',
    },
    timeStyles: {
      fontSize: '20px',
      color: 'rgba(208, 2, 27, 1)',
    },
  },
  [structureTypes.PHYSICAL_PRODUCT]: {
    attributeStyles: {
      fontSize: '14px',
    },
    attributeOptionStyles: {
      fontSize: '14px',
    },
  },
  [structureTypes.COUPON]: {
    fieldStyles: {
      fontSize: '14px',
    },
    buttonStyles: {
      fontSize: '14px',
    },
    fieldOptions: {},
    buttonOptions: {},
  },
}

export const editorZindex = {
  header: 40,
  sidebar: 35,
  colorPicker: 40,
  settingsOverlay: 30,
  colorPickerOverlay: 30,
  actionsMenu: 20,
  popupSettingsButton: 30,
  popupManagementOverlay: 10,
  actionsMenuItem: 10,
  colorPreview: 0,
}

export const mimeTypes = {
  image: ['image/*'],
  video: ['video/mp4', 'video/ogg', 'video/webm'],
  audio: [
    'audio/ogg',
    'audio/mpeg',
    'audio/mp3',
    'audio/wav',
    'audio/wave',
    'audio/x-wav',
    'audio/flac',
    'audio/x-flac',
  ],
  font: ['font/ttf'],
  all: [
    'image/*',
    'video/*',
    'audio/*',
    'application/*',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.apple.numbers',
    'application/pdf',
    'text/*',
  ],
}

export const upsellPricePlansStub = [
  {
    id: 3,
    name: 'entities.offer_upsell_price.stub.name',
    price: '1400 euro',
  },
]

export const pricePlansStub = [
  {
    id: 1,
    name: 'entities.offer_price.stub.name',
    currency: 'eur',
    type: 'limited_subscription',
    isLimited: true,
    subscriptionPlan: {
      amount: 800,
      interval: 'month',
      intervalCount: 1,
      trialPeriod: 30,
      trialInterval: 'day',
      limitOfPayments: 3,
    },
  },
  {
    id: 2,
    name: 'entities.offer_price.stub.name',
    currency: 'eur',
    type: 'one_shot',
    isLimited: false,
    directChargeAmount: 4750,
  },
  {
    id: 3,
    name: 'entities.offer_price.stub.name',
    currency: 'eur',
    type: 'subscription',
    isLimited: false,
    subscriptionPlan: {
      amount: 4350,
      interval: 'month',
      intervalCount: 1,
      trialPeriod: 0,
      limitOfPayments: null,
    },
  },
]

export const bumpStub = [
  {
    id: 1,
    pricePlans: [
      {
        id: 1,
        name: 'entities.offer_price.stub.name',
        subscriptionPlan: {
          amount: 800,
          interval: 'month',
          trialPeriod: 30,
          trialInterval: 'day',
          limitOfPayments: 3,
        },
      },
    ],
  },
]

export const browserDefaultLineHeight = 1.3
