import ServerPage from 'server/types/ServerPageInterface'

export function migrateCommand<P, N>({
  page,
  structureType,
  migrateFunction,
}: {
  page: ServerPage
  structureType: string
  migrateFunction: (entity: P) => N
}): [ServerPage, boolean] {
  let isChanged = false
  try {
    const entities = Object.values(page.content.entities).reduce(
      (prev, entity) => {
        if (entity.type === structureType) {
          isChanged = true
        }

        return {
          ...prev,
          [entity.id]:
            entity.type === structureType
              ? migrateFunction(entity as P)
              : entity,
        }
      },
      {},
    )
    return [
      {
        ...page,
        content: {
          ...page.content,
          entities,
        },
      },
      isChanged,
    ]
  } catch (e) {
    console.log(`--error--`, e)

    return [page, false]
  }
}
