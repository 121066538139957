import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import { createHeadline } from 'client/components/entities/Headline'
import {
  migrateMargin,
  migrateMobileMargin,
  migratePadding,
} from '../utils/migrateUtils'

export function migrateHeadline(
  entity: OldTextEntityInterface,
): HeadlineInterface {
  const headline = createHeadline(entity.parentId, '', entity.masterBlockId)
  // old text headline didn't have paddings by default
  delete headline.padding
  delete headline.mobilePadding

  headline.id = entity.id
  if (entity.isMasterBlockRoot) {
    headline.isMasterBlockRoot = true
  }
  headline.rawContentState = entity.options.rawContentState

  migrateMargin(headline, entity)

  if (entity.styles.padding) {
    headline.padding = migratePadding(entity.styles.padding)
  }

  if (entity.options.appearance) {
    headline.appearance.desktop = entity.options.appearance.desktop
    headline.appearance.mobile = entity.options.appearance.mobile
  }

  headline.htmlAttrId = entity.options.attrId
  headline.textAlign = entity.styles.textAlign
  if (entity.styles.fontFamily) {
    headline.fontFamily = entity.styles.fontFamily

    if (entity.styles.fontFamily === 'inherit') {
      // we don't need to keep style and weight if we have inherit value
    } else {
      if (entity.styles.fontWeight) {
        headline.fontWeight = entity.styles.fontWeight
      }
      if (entity.styles.fontStyle) {
        headline.fontStyle = entity.styles.fontStyle
      }
    }
  }

  if (entity.styles.backgroundColor) {
    headline.backgroundColor = entity.styles.backgroundColor
  }

  if (
    entity.styles.letterSpacing &&
    !isNaN(parseInt(entity.styles.letterSpacing))
  ) {
    headline.letterSpacing = parseInt(entity.styles.letterSpacing)
  }
  if (entity.styles.fontSize && !isNaN(parseInt(entity.styles.fontSize))) {
    headline.fontSize = parseInt(entity.styles.fontSize)
  }
  if (entity.styles.lineHeight && !isNaN(parseInt(entity.styles.lineHeight))) {
    headline.lineHeight = parseInt(entity.styles.lineHeight)
  }

  // MOBILE SETTINGS
  if (
    entity.mobileStyles.letterSpacing &&
    !isNaN(parseInt(entity.mobileStyles.letterSpacing))
  ) {
    headline.mobileLetterSpacing = parseInt(entity.mobileStyles.letterSpacing)
  }
  if (
    entity.mobileStyles.fontSize &&
    !isNaN(parseInt(entity.mobileStyles.fontSize))
  ) {
    headline.mobileFontSize = parseInt(entity.mobileStyles.fontSize)
  }
  if (
    entity.mobileStyles.lineHeight &&
    !isNaN(parseInt(entity.mobileStyles.lineHeight))
  ) {
    headline.mobileLineHeight = parseInt(entity.mobileStyles.lineHeight)
  }

  if (entity.mobileStyles.padding) {
    headline.mobilePadding = migratePadding(entity.mobileStyles.padding)
  }

  migrateMobileMargin(headline, entity)

  if (entity.mobileStyles.fontFamily) {
    headline.mobileFontFamily = entity.mobileStyles.fontFamily
    if (entity.mobileStyles.fontFamily === 'inherit') {
      // we don't need to keep style and weight if we have inherit value
    } else {
      if (entity.mobileStyles.fontWeight) {
        headline.mobileFontWeight = entity.mobileStyles.fontWeight
      }
      if (entity.mobileStyles.fontStyle) {
        headline.mobileFontStyle = entity.mobileStyles.fontStyle
      }
    }
  }

  if (entity.mobileStyles.backgroundColor) {
    headline.mobileBackgroundColor = entity.mobileStyles.backgroundColor
  }

  if (entity.mobileStyles.textAlign) {
    headline.mobileTextAlign = entity.mobileStyles.textAlign
  }

  return headline
}
