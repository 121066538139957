import { ColumnTypeEnum } from 'common/components/entities/Column/enums/ColumnTypeEnum'
import structureTypes from 'common/constants/structureTypes'
import { ButtonActionTypeEnum } from 'common/enums/ButtonActionTypeEnum'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import {
  BaseEntityWithChildIdsInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { createText } from 'client/components/entities/Text/Text'
import i18n from 'client/i18n'
import { createNewCustomLogin } from 'client/pages/custom-login/entities/CustomLogin'
import { createForgotPassword } from 'client/pages/custom-login/entities/ForgotPassword'
import {
  createContainerStructure,
  createEntity,
  generateCustomHtmlAttrId,
  insertChild,
} from 'client/utils/createStructureUtils'
import { createCheckbox } from '../../Settings/entities/CheckboxSettings/utils/create-checkbox'
import { createColumn } from '../../Settings/entities/RowSettings/utils/create-column'
import { createRowColumnLayout } from '../../Settings/entities/RowSettings/utils/create-row-column-layout'

const DEFAULT_EMAIL_PLACEHOLDER = 'entities.field.email.default_placeholder'
const DEFAULT_PASSWORD_PLACEHOLDER =
  'entities.member_access.default_password_placeholder'
const DEFAULT_PASSWORD_LABEL = 'entities.member_access.default_password_label'
const DEFAULT_EMAIL_LABEL = 'entities.member_access.default_email_label'

export function createCustomLoginFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
  oldId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const newCustomLogin = createNewCustomLogin(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )
  if (oldId) {
    newCustomLogin.id = oldId
  }

  const { loginFormEntities, loginFormEntityId } = createLoginFormEntities(
    newCustomLogin,
    locale,
    position,
  )

  const { registrationFormEntities, registrationFormEntityId } =
    createRegistrationFormEntities(newCustomLogin, locale, position)

  const { resettingRequestFormEntities, resettingRequestFormEntityId } =
    createResettingRequestFormEntities(newCustomLogin, locale)

  const { resettingResetFormEntities, resettingResetFormEntityId } =
    createResettingResetFormEntities(newCustomLogin, locale)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        newCustomLogin.id,
        position,
      ),
    },
    [newCustomLogin.id]: {
      ...newCustomLogin,
      parentId: descendantEntity.id,
      childIds: [
        loginFormEntityId,
        registrationFormEntityId,
        resettingRequestFormEntityId,
        resettingResetFormEntityId,
      ],
    },
    ...loginFormEntities,
    ...registrationFormEntities,
    ...resettingRequestFormEntities,
    ...resettingResetFormEntities,
  }
}

export function createLabel(
  labelText: string,
  container: BaseEntityWithChildIdsInterface,
  htmlAttrPrefix: string,
) {
  const labelEntity = createTextElement(labelText, container, htmlAttrPrefix)
  labelEntity.fontSize = 18
  labelEntity.margin.marginTop = 10
  labelEntity.textAlign = 'left'

  return labelEntity
}

function createHeader(
  labelText: string,
  container: BaseEntityWithChildIdsInterface,
  htmlAttrPrefix: string,
) {
  const labelEntity = createTextElement(labelText, container, htmlAttrPrefix)
  labelEntity.fontSize = 34
  labelEntity.margin.marginTop = 10

  return labelEntity
}

function createTextElement(
  labelText: string,
  container: BaseEntityWithChildIdsInterface,
  htmlAttrPrefix: string,
) {
  const labelEntity = createText(container.id, container.masterBlockId)
  labelEntity.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${labelText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${labelText.length},"style":"rgba(20,45,99,100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  labelEntity.fontSize = 34
  labelEntity.margin.marginTop = 10
  labelEntity.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    htmlAttrPrefix,
  )

  return labelEntity
}

export function createField(
  container: BaseEntityWithChildIdsInterface,
  locale: string,
  placeholder: string,
  slug: FieldSlugEnum,
) {
  return createEntity(
    structureTypes.FORM_INPUT,
    container.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    {
      inputType: 'text',
      placeholder: `${placeholder}`,
      slug,
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
}

function createButton(
  container: BaseEntityWithChildIdsInterface,
  text: string,
  locale: string,
  action: ButtonActionTypeEnum,
) {
  return createEntity(
    structureTypes.BUTTON,
    container.id,
    locale,
    container.masterBlockId,
    {
      width: '100%',
      backgroundColor: 'rgba(0, 160, 225, 100)',
      borderStyle: 'none',
    },
    {
      text,
      action,
      subText: '',
      redirectionType: '',
      borderType: 'bottomOnly',
      subTextFontSize: '14px',
      subTextColor: 'rgba(255,255,255,0.8)',
    },
  )
}

function createResettingRequestFormEntities(
  parentEntity: OldEntityWithChildIds | BaseEntityWithChildIdsInterface,
  locale: string,
) {
  const container = createColumn({ parentId: parentEntity.id })

  const fixedT = i18n.getFixedT(locale)

  const header = createHeader(
    fixedT('entities.member_access.request.default_headline'),
    container,
    'reset-pass-header',
  )

  const emailLabel = createLabel(
    fixedT(DEFAULT_EMAIL_LABEL),
    container,
    'reset-pass-email-label',
  )

  const emailField = createField(
    container,
    locale,
    fixedT(DEFAULT_EMAIL_PLACEHOLDER),
    FieldSlugEnum.Email,
  )

  const button = createButton(
    container,
    fixedT('entities.member_access.request.default_button_text'),
    locale,
    ButtonActionTypeEnum.ResetRequest,
  )

  return {
    resettingRequestFormEntities: {
      [container.id]: {
        ...container,
        childIds: [header.id, emailLabel.id, emailField.id, button.id],
      },
      [header.id]: header,
      [emailLabel.id]: emailLabel,
      [emailField.id]: emailField,
      [button.id]: button,
    },
    resettingRequestFormEntityId: container.id,
  }
}

function createRegistrationFormEntities(
  parentEntity: OldEntityWithChildIds | BaseEntityWithChildIdsInterface,
  locale: string,
  position: number,
) {
  const { column1: container } = createRowColumnLayout({
    parentEntity,
    position,
  })

  const fixedT = i18n.getFixedT(locale)

  const header = createHeader(
    fixedT('entities.member_access.registration.default_headline'),
    container,
    'conf-reg-header',
  )

  const passwordLabel = createLabel(
    fixedT(DEFAULT_PASSWORD_LABEL),
    container,
    'pass-conf-header',
  )

  const firstNameLabel = createLabel(
    fixedT('entities.member_access.default_first_name_label'),
    container,
    'first-name-header',
  )

  const firstNameField = createField(
    container,
    locale,
    fixedT('entities.member_access.default_first_name_placeholder'),
    FieldSlugEnum.FirstName,
  )

  const lastNameLabel = createLabel(
    fixedT('entities.member_access.default_last_name_label'),
    container,
    'last-name-label',
  )

  const lastNameField = createField(
    container,
    locale,
    fixedT('entities.member_access.default_last_name_placeholder'),
    FieldSlugEnum.LastName,
  )

  const passwordField = createField(
    container,
    locale,
    fixedT(DEFAULT_PASSWORD_PLACEHOLDER),
    FieldSlugEnum.Password,
  )

  const confirmPasswordLabel = createLabel(
    fixedT('entities.member_access.default_password_confirm_label'),
    container,
    'conf-pass-label',
  )

  const confirmPasswordField = createField(
    container,
    locale,
    fixedT(DEFAULT_PASSWORD_PLACEHOLDER),
    FieldSlugEnum.ConfirmPassword,
  )

  const button = createButton(
    container,
    fixedT('entities.member_access.registration.default_button_text'),
    locale,
    ButtonActionTypeEnum.ConfirmRegistration,
  )

  return {
    registrationFormEntities: {
      [container.id]: {
        ...container,
        childIds: [
          header.id,
          firstNameLabel.id,
          firstNameField.id,
          lastNameLabel.id,
          lastNameField.id,
          passwordLabel.id,
          passwordField.id,
          confirmPasswordLabel.id,
          confirmPasswordField.id,
          button.id,
        ],
      },
      [header.id]: header,
      [firstNameLabel.id]: firstNameLabel,
      [firstNameField.id]: firstNameField,
      [lastNameLabel.id]: lastNameLabel,
      [lastNameField.id]: lastNameField,
      [passwordLabel.id]: passwordLabel,
      [passwordField.id]: passwordField,
      [confirmPasswordLabel.id]: confirmPasswordLabel,
      [confirmPasswordField.id]: confirmPasswordField,
      [button.id]: button,
    },
    registrationFormEntityId: container.id,
  }
}

function createLoginFormEntities(
  parentEntity: OldEntityWithChildIds | BaseEntityWithChildIdsInterface,
  locale: string,
  position: number,
) {
  const fixedT = i18n.getFixedT(locale)
  const { column1: loginContainer } = createRowColumnLayout({
    parentEntity,
    position,
  })

  const header = createHeader(
    fixedT('entities.member_access.login.default_headline'),
    loginContainer,
    'login-header',
  )

  const subHeader = createLabel(
    fixedT('entities.member_access.login.default_sub_headline'),
    loginContainer,
    'login-sub-header',
  )

  const emailLabel = createLabel(
    fixedT(DEFAULT_EMAIL_LABEL),
    loginContainer,
    'email-header',
  )

  const emailField = createField(
    loginContainer,
    locale,
    fixedT(DEFAULT_EMAIL_PLACEHOLDER),
    FieldSlugEnum.Email,
  )

  const passwordLabel = createLabel(
    fixedT(DEFAULT_PASSWORD_LABEL),
    loginContainer,
    'ps-header',
  )

  const passwordLoginInput = createField(
    loginContainer,
    locale,
    fixedT(DEFAULT_PASSWORD_PLACEHOLDER),
    FieldSlugEnum.Password,
  )

  const button = createButton(
    loginContainer,
    fixedT('entities.member_access.login.default_button_text'),
    locale,
    ButtonActionTypeEnum.Login,
  )

  const { row, column1, column2 } = createRowColumnLayout({
    parentEntity: loginContainer,
    position,
    columnType: ColumnTypeEnum.COLUMN_6,
  })

  const stayConnectedCheckbox = createCheckbox(
    column1.id,
    fixedT('entities.member_access.login.default_checkbox'),
    column1.masterBlockId,
  )
  stayConnectedCheckbox.errorText = fixedT('settings_options.checkbox_message')

  const forgotPasswordLink = createForgotPassword({
    locale,
    parentId: column2.id,
    masterBlockId: column1.masterBlockId,
  })

  return {
    loginFormEntities: {
      [loginContainer.id]: {
        ...loginContainer,
        parentId: parentEntity.id,
        childIds: [
          header.id,
          subHeader.id,
          emailLabel.id,
          emailField.id,
          passwordLabel.id,
          passwordLoginInput.id,
          row.id,
          button.id,
        ],
      },
      [column2.id]: {
        ...column2,
        childIds: [forgotPasswordLink.id],
      },
      [column1.id]: {
        ...column2,
        childIds: [stayConnectedCheckbox.id],
      },
      [forgotPasswordLink.id]: forgotPasswordLink,
      [stayConnectedCheckbox.id]: stayConnectedCheckbox,
      [header.id]: header,
      [subHeader.id]: subHeader,
      [emailLabel.id]: emailLabel,
      [emailField.id]: emailField,
      [passwordLabel.id]: passwordLabel,
      [passwordLoginInput.id]: passwordLoginInput,
      [row.id]: row,
      [button.id]: button,
    },
    loginFormEntityId: loginContainer.id,
  }
}

export function createResettingResetFormEntities(
  parentEntity: OldEntityWithChildIds | BaseEntityWithChildIdsInterface,
  locale: string,
) {
  const container = createColumn({ parentId: parentEntity.id })

  const fixedT = i18n.getFixedT(locale)

  const header = createHeader(
    fixedT('entities.member_access.reset_password.default_headline'),
    container,
    'reset-pass-header',
  )

  const passwordLabel = createLabel(
    fixedT(DEFAULT_PASSWORD_LABEL),
    container,
    'resetting-reset-pass-label',
  )

  const passwordField = createField(
    container,
    locale,
    fixedT(DEFAULT_PASSWORD_PLACEHOLDER),
    FieldSlugEnum.Password,
  )

  const button = createButton(
    container,
    fixedT('entities.member_access.request.default_button_text'),
    locale,
    ButtonActionTypeEnum.ResetPassword,
  )

  return {
    resettingResetFormEntities: {
      [container.id]: {
        ...container,
        childIds: [header.id, passwordLabel.id, passwordField.id, button.id],
      },
      [header.id]: header,
      [passwordLabel.id]: passwordLabel,
      [passwordField.id]: passwordField,
      [button.id]: button,
    },
    resettingResetFormEntityId: container.id,
  }
}
