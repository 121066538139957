import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { createText } from 'client/components/entities/Text/Text'
import i18n from 'client/i18n'
import { createOrderBump } from 'client/pages/offer/entities/order-bump'
import {
  createContainerStructure,
  insertChild,
} from 'client/utils/createStructureUtils'
import { createRowColumnLayout } from '../../Settings/entities/RowSettings/utils/create-row-column-layout'

export function createOrderBumpFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)
  const title = fixedT('entities.order_bump.default_title')
  const orderBump = createOrderBump(
    container.id,
    title,
    container.masterBlockId,
  )

  const {
    row,
    column1,
    structure: rowColumnStructure,
  } = createRowColumnLayout({
    parentEntity: orderBump,
    position: 0,
  })

  orderBump.childIds = [row.id]
  row.padding = {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }
  row.mobilePadding = {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
  }

  const text = createText(column1.id, column1.masterBlockId)

  return {
    ...structure,
    ...rowColumnStructure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, orderBump.id, position),
    },
    [orderBump.id]: orderBump,
    [column1.id]: {
      ...column1,
      childIds: [text.id],
    },
    [text.id]: text,
  }
}
